import axios from 'axios';

import { ssService } from './ajax-common-ss';

export const cafesService = {
  getAll,
  create,
  update,
  remove,
};

async function getAll(forced=false, org_id=null, skip=0, limit=100) {
  return ssService.getCached("cafes", 5, forced, getAllOld, org_id, skip, limit);
}

function getAllOld(org_id=null, skip=0, limit=100) {
  return axios.get('/api/v1/cafe/', {
    params: {
      org_id: org_id,
      skip: skip,
      limit: limit,
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

function create(name, description, is_blocked, org_id) {
  return axios.post('/api/v1/cafe/', {
    withCredentials: true,

    name: name,
    description: description,
    is_blocked: is_blocked,
    org_id: org_id,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function update(id, name, description, is_blocked, org_id) {
  return axios.patch('/api/v1/cafe/' + id, {
    withCredentials: true,

    name: name,
    description: description,
    is_blocked: is_blocked,
    org_id: org_id,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

function remove(id) {
  return axios.delete('/api/v1/cafe/' + id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

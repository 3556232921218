<template>
  <div>
    <h1>Collections</h1>
    <div v-if="error" class="alert alert-danger mb-3">{{ error }}</div>

    <CreateColModal :method_to_refresh="getAllCollections" />


    <button class="btn btn-primary py-2 mr-2" type="submit" v-bind:disabled="!btn_refresh_col_is_active"
      @click="getAllCollections">
      <span v-if="btn_refresh_col_loading" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      Refresh
    </button>

    <button class="btn btn-primary py-2 mr-2" type="submit" data-bs-toggle="modal" data-bs-target="#createModal">
      <span v-if="btn_create_col_loading" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      Create
    </button>

    <button class="btn btn-primary py-2 mr-2" type="submit" @click="selectAllCol"
      v-bind:disabled="!areCollectionsPresent || areAllSelected">
      <span v-if="btn_selall_col_loading" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      Select All
    </button>

    <button class="btn btn-primary py-2 mr-2" type="submit" @click="unSelectAllCol"
      v-bind:disabled="!isAtLeastOneSelected">
      <span v-if="btn_unselall_col_loading" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      Unselect All
    </button>

    <button class="btn btn-primary py-2 mr-2" type="submit" @click="editCol" v-bind:disabled="!isOnlyOneSelected">
      <span v-if="btn_edit_col_loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Edit
    </button>

    <button class="btn btn-primary py-2" type="submit" @click="deleteSelectedCollections"
      v-bind:disabled="!isAtLeastOneSelected || !btn_delete_col_is_active">
      <span v-if="btn_delete_col_loading" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      Delete
    </button>


    <div class="m-0 border-0 table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">id</th>
            <th scope="col">name</th>
            <th scope="col">description</th>
            <th scope="col">is_public</th>
            <th scope="col">created_at</th>
            <th scope="col">update_on</th>
            <th scope="col">owner_id</th>
          </tr>
        </thead>
        <tbody>

          <tr v-for="s_collection in collections" :key="s_collection.id"
            :class="{ 'table-active': isSelectedCol(s_collection.id) }" @click="toggleSelectionCol(s_collection.id)">
            <th scope="row">{{ s_collection.id }}</th>
            <td>{{ s_collection.name }}</td>
            <td>{{ s_collection.description }}</td>
            <td>{{ s_collection.is_public }}</td>
            <td>{{ s_collection.created_at }}</td>
            <td>{{ s_collection.update_on }}</td>
            <td>{{ s_collection.owner_id }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CreateColModal from '@/components/modal-eav-create_col.vue'
import { collectionsService } from "@/components/ajax-eav-collection.js";
import { accountsService } from "@/components/ajax-common-account.js";

export default {
  name: "AdminCollections",

  components: {
    CreateColModal,
  },

  data() {
    return {
      error: '',

      collections: '',

      btn_refresh_col_loading: false,
      btn_refresh_col_is_active: true,
      btn_create_col_loading: false,
      btn_selall_col_loading: false,
      btn_unselall_col_loading: false,
      btn_edit_col_loading: false,
      btn_delete_col_is_active: true,
      btn_delete_col_loading: false,

      selectedCollections: [],
      isOnlyOneSelected: false,
      isAtLeastOneSelected: false,
      areCollectionsPresent: false,
      areAllSelected: false,
    }
  },

  watch: {
  },
  computed: {
    form_name_is_valid() {
      const trimmedName = this.form_name.trim();
      return trimmedName.length >= 8;
    }
  },
  methods: {
    async setError(text) {
      this.error = text;
      // await new Promise(resolve => setTimeout(resolve, 5000));
      // this.error = '';
    },
    getAllCollections() {
      this.btn_refresh_col_loading = true;
      collectionsService.getAll()
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.collections = data;

          this.btn_refresh_col_loading = false;
          this.countCol();
        })
        .catch(
          async error => {
            this.btn_refresh_col_loading = false;

            if (error.body) {
              this.setError(error.body);

              accountsService.checkIfLogin(error.body, true);

              if (error.body === "Too Many Requests") {
                this.btn_refresh_col_is_active = false;
                await new Promise(resolve => setTimeout(resolve, 5000));
                this.btn_refresh_col_is_active = true;
              }
            } else {
              this.setError(error.error);
            }
          }
        );
    },
    toggleSelectionCol(collectionId) {
      if (this.isSelectedCol(collectionId)) {
        // Удалить ID коллекции из массива, если она уже выбрана
        const index = this.selectedCollections.indexOf(collectionId);
        this.selectedCollections.splice(index, 1);
      } else {
        // Добавить ID коллекции в массив, если она еще не выбрана
        this.selectedCollections.push(collectionId);
      }
      this.countCol();
    },
    isSelectedCol(collectionId) {
      // Проверить, выбрана ли коллекция
      return this.selectedCollections.includes(collectionId);
    },
    selectAllCol() {
      // Добавить все ID коллекций в массив выбранных
      this.selectedCollections = this.collections.map(collection => collection.id);
      this.countCol();
    },
    unSelectAllCol() {
      // Очистить массив выбранных коллекций
      this.selectedCollections = [];
      this.countCol();
    },
    countCol() {
      this.isOnlyOneSelected = this.selectedCollections.length === 1;
      this.isAtLeastOneSelected = this.selectedCollections.length > 0;
      this.areCollectionsPresent = this.collections.length > 0;
      this.areAllSelected = this.selectedCollections.length === this.collections.length;
    },
    editCol() { },
    async deleteSelectedCollections() {
      await this.doSequentially(this.selectedCollections, this.deleteCollection)
    },
    async doSequentially(list, func) {
      if (list.length === 0) return;
      this.btn_delete_col_loading = true;
      const currentItem = list.shift();

      try {
        await func(currentItem);
        await this.doSequentially(list, func);
      } catch (error) {
        list.push(currentItem);
        this.setError("Error in doSequentially(): " + error);
      }
    },
    deleteCollectionFrInterface(id) {
      const index1 = this.collections.findIndex(item => item.id === id);
      if (index1 !== -1) {
        this.collections.splice(index1, 1);
      }

      // delete selected collection from list
      // const index2 = this.selectedCollections.indexOf(id);
      // if (index2 !== -1) {
      //   this.selectedCollections.splice(index2, 1);
      // }

      this.countCol();
    },
    async deleteCollection(id) {
      await collectionsService.remove(id)
        .then(data => {
          if (data === null) {
            this.deleteCollectionFrInterface(id);
          }
          this.btn_delete_col_loading = false;
          return true;
        })
        .catch(
          error => {
            this.btn_delete_col_loading = false;
            if (error.body) {
              const responseBody = error.body;
              this.setError("Error from server: " + responseBody);

              if (responseBody === "Too Many Requests") {
                this.btn_delete_col_is_active = false;
                new Promise(resolve => setTimeout(resolve, 5000));
                this.btn_delete_col_is_active = true;
              }
            } else {
              this.setError("Error: " + error.error);
            }
            return false;
          }
        );
    },
  },
  mounted() {
    this.getAllCollections();
  },
  created() {
  }
}
</script>
<style>
.table-container {
  max-width: 100%;
  /* Максимальная ширина контейнера */
  overflow-x: auto;
  /* Появление горизонтальной прокрутки при необходимости */
}

.table {
  width: 100%;
  /* Ширина таблицы равна 100% ширины контейнера */
  /* white-space: nowrap; Текст внутри ячеек не переносится */
}

.table th,
.table td {
  /* Сокрытие текста, который не помещается в ячейку */
  overflow: hidden;
  /* Отображение многоточия, если текст не помещается в ячейку */
  text-overflow: ellipsis;
  /* Перенос текста на следующую строку, если необходимо */
  word-wrap: break-word;
}</style>
<template>
  <div class="modal fade" ref="modal" id="updateModal" tabindex="-1" aria-labelledby="updateModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="updateItem">

          <div class="modal-header">
            <h5 class="modal-title" id="updateModalLabel">Update Org</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              @click="cleanupUpdateForm"></button>
          </div>
          <div class="modal-body">
            <div v-if="form_error" class="alert alert-danger mb-3">{{ form_error }}</div>

            <div class="form-floating my-3">
              <input type="text" class="form-control" v-model="formdata_name" name="formdata_name" id="formdata_name"
                placeholder="Org name"
                :class="{ 'is-invalid': form_submitted && (!formdata_name || !formdata_name_is_valid) }" />
              <label for="formdata_name" v-show="!form_submitted || formdata_name">Org</label>
              <div v-show="form_submitted && !formdata_name" class="invalid-feedback">Name is required</div>
              <div v-show="form_submitted && !formdata_name_is_valid" class="invalid-feedback">Name must be at least 4
                characters long</div>
            </div>

            <div class="form-floating my-3">
              <textarea class="form-control" v-model="formdata_description" name="formdata_description" id="formdata_description"
                placeholder="Org description" style="height: 200px;"></textarea>
              <label for="formdata_description">Description</label>
            </div>

            <div class="form-check text-start my-3">
              <input class="form-check-input" type="checkbox" v-model="formdata_is_blocked" name="formdata_is_blocked"
                value="formdata_is_blocked" id="formdata_is_blocked" data-bs-toggle="tooltip"
                title="Check this box if the org should be blocked">
              <label class="form-check-label" for="formdata_is_blocked">
                Blocked org
              </label>
            </div>

          </div>


          <div class="modal-footer">
            <button type="button" class="btn btn-secondary py-2" data-bs-dismiss="modal"
              @click="cleanupUpdateForm">Close</button>
            <button type="submit" class="btn btn-primary py-2" v-bind:disabled="form_is_loading"><span v-if="form_is_loading"
                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Save</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { accountsService } from "@/components/ajax-common-account.js";
import { orgsService } from "@/components/ajax-smartmcw-org.js";

export default {
  name: 'UpdateOrgModal',
  data() {
    return {
      form_error: '',
      form_submitted: false,
      form_is_loading: false,

      formdata_id: '',
      formdata_name: '',
      formdata_description: '',
      formdata_is_blocked: false,
    };
  },
  props: {
    method_to_refresh: {
      type: Function,
      required: true
    },
    organizations: {
      type: Array,
      default: function () {
        return [];
      },
      required: false,
    },
    getSelItem: {
      type: Function,
      required: true
    },
  },
  methods: {
    async setError(text) {
      this.error = text;
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.error = '';
    },
    renderForm() {
      const data = this.getSelItem();

      if (this.$is_debug) console.log("Response message:", data);
      this.formdata_id = data.id;
      this.formdata_name = data.name;
      this.formdata_description = data.description;
      this.formdata_is_blocked = data.is_blocked;
    },
    trimInput() {
      this.formdata_name = this.formdata_name.trim();
    },
    closeModal() {
      this.form_error = '';
      var myModalEl = document.getElementById('updateModal')
      var modal = Modal.getInstance(myModalEl);
      modal.hide();
    },
    updateItem() {
      this.form_submitted = true;
      const { formdata_id, formdata_name, formdata_description, formdata_is_blocked } = this;

      if (!formdata_id) {
        this.setError("Org ID wasn't been provided.")
        return;
      }

      // console.log(remember_me);
      // stop here if form is invalid
      if (!formdata_name || !this.formdata_name_is_valid) {
        return;
      }

      // console.log(email, password);

      this.form_is_loading = true;
      orgsService.update(formdata_id, formdata_name, formdata_description, formdata_is_blocked)
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          // router.push("/home");
          this.cleanupUpdateForm();
          this.closeModal();
          this.method_to_refresh(true);
          this.form_is_loading = false;
        })
        .catch(
          error => {
            if (error.body) {
              this.form_error = error.body;

              accountsService.checkIfLogin(error.body, true);

            } else {
              this.form_error = error.error;
            }
            this.form_is_loading = false;
          }
        );
    },
    cleanupUpdateForm() {
      this.form_error = '';
      this.formdata_name = '';
      this.formdata_description = '';
      this.formdata_is_blocked = false;

      this.form_submitted = false;
    },
  },
  computed: {
    organizations_wo_first() {
      return this.organizations.slice(1);
    },
    formdata_name_is_valid() {
      const trimmedName = this.formdata_name.trim();
      return trimmedName.length >= 4;
    }
  },
  created() {
  },
  mounted() {
    this.$refs.modal.addEventListener('shown.bs.modal', () => {
      this.renderForm();
    });

  }
};
</script>

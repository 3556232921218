<template>
  <div>
    <h1>Welcome to the Workspace</h1>

    <br />
    <div class="alert alert-primary d-flex align-items-center" role="alert">
      <div>
        Данная инструкция всегда доступна при нажатии на иконку профиля в нижней части меню-бара.
      </div>
    </div>

    <br />
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
            aria-expanded="true" aria-controls="collapseOne">
            Инструкция для сотрудника
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>Если <strong>вы сотрудник кафе</strong>, то представьте вашему работодателю электронную почту,
              используемую при регистрации, с просьбой предоставить вам доступ к управлению заведением.</p>
            <div class="alert alert-info" role="alert">
              Проверить, какую почту вы
              использовали можно в левом нижнем углу, открыв свой профиль. Или по <router-link to="/home/profile">этой
                ссылке</router-link>.
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Инструкция для владельца
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ol>
              <li>Добавьте свою организацию в разделе <router-link to="/admin/orgs">Organizations</router-link>. Если у
                вас несколько ораганизаций с разными меню добавьте каждую организацию отдельно.</li>
              <li>Добавьте ваши заведения в разделе <router-link to="/admin/cafes">Cafes</router-link>.</li>
              <li>В разделе <router-link to="/admin/menus">Menus</router-link> заполните ваши позиции меню и привяжите
                их к кафе.</li>
              <li>Добавьте умные микроволновки/конвектоматы в разделе <router-link to="/admin/mcws">Mcws</router-link>.
              </li>
              <li>После этого вы можете открыть раздел <router-link to="/home/barista">Barista</router-link> и
                проверить, как интерфейс будет
                выглядеть у
                вашего сотрудника.</li>
              <li>
                <p>Далее вы можете предоставить доступ к вашим сотрудникам на определенные кафе.</p>
                <div class="alert alert-info" role="alert">
                  Временно, для этого свяжись с администратором ресурса предоставив название вашей организации и
                  электронной почты сотрудника (после его регистрации).
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "InfoView",
}
</script>

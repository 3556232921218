
import { ssService } from './ajax-common-ss';
import axios from 'axios';

export const rolesService = {
  getAll,
  attach,
  deattach,
  getUserRoles,
  decline,
  accept,
};

async function decline(role_id) {
  return axios.post('/api/v1/roles/decline/' + role_id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

async function accept(role_id) {
  return axios.post('/api/v1/roles/accept/' + role_id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


async function getAll(forced = false, object_id, skip = 0, limit = 100) {
  return ssService.getCached("roles", 5, forced, getAllOld, object_id, skip, limit);
}


async function getUserRoles(forced = false, skip = 0, limit = 100) {
  return ssService.getCached("user_roles", 5, forced, getUserRolesDirect, skip, limit);
}


async function getUserRolesDirect(skip = 0, limit = 100) {
  return axios.get('/api/v1/roles/', {
    params: {
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


async function getAllOld(object_id, skip = 0, limit = 100) {
  return axios.get('/api/v1/roles/' + object_id, {
    params: {
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


async function attach(user_email, roles) {
  if (!roles || roles.length === 0) {
    return;
    // return Promise.reject(new Error("The roles list is empty."));
  }

  return axios.post('/api/v1/roles/attach', {
    withCredentials: true,
    user_email: user_email,
    role_ids: roles,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function deattach(user_email, roles) {
  if (!roles || roles.length === 0) {
    return;
    // return Promise.reject(new Error("The roles list is empty."));
  }

  return axios.post('/api/v1/roles/deattach', {
    withCredentials: true,
    user_email: user_email,
    role_ids: roles,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

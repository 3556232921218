<template>
  <div class="modal fade" ref="updateModal" id="updateModal" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="updateItem">

          <div class="modal-header">
            <h5 class="modal-title" id="updateModalLabel">New Menu</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cleanupForm"></button>
          </div>
          <div class="modal-body">
            <div v-if="form_error" class="alert alert-danger mb-3">{{ form_error }}</div>

            <!-- https://getbootstrap.com/docs/5.0/forms/select/ -->
            <div class="form-floating">
              <select class="form-select" id="orgSelect" v-model="formdata_org_id" @change="updateCafeList">
                <option value="" disabled selected hidden>{{ organizations.length === 0 ? 'Create an organization first' : 'Choose an organization' }}</option>
                <option v-for="org in organizations" v-bind:key="org.id" v-bind:value="org.id">{{ org.name }} ({{ org.id }})</option>
              </select>
              <label for="orgSelect">Organization:</label>
            </div>

            <div class="form-floating my-3">
              <select class="form-select" id="cafeSelect" v-model="formdata_sel_index_cafe" @change="addCafe" :disabled="availableCafes.length === 0">
                <option value="" disabled selected hidden>{{ availableCafes.length === 0 ? 'There are no available cafes in the organization' : 'Choose cafes' }}</option>
                <option v-for="cafe in availableCafes" :key="cafe.id" :value="cafe.id">{{ cafe.name }}</option>
              </select>
              <label for="cafeSelect">Cafes:</label>
              <div class="tags my-3">
                <span v-for="cafe in formdata_cafes" :key="cafe.id" class="badge bg-primary" @click="removeCafe(cafe.id)" style="cursor: pointer;">{{ cafe.name }} <span>&times;</span></span>
              </div>
            </div>

            <div class="form-floating my-3">
              <CropperModal :setUpdatedImage="setUpdatedImage" :current_image="formdata_cover_image" />
            </div>

            <div class="form-floating my-3">
              <input type="text" class="form-control" v-model="formdata_name" name="formdata_name" id="formdata_name"
                placeholder="Name"
                :class="{ 'is-invalid': form_submitted && (!formdata_name || !formdata_name_is_valid) }" />
              <label for="formdata_name" v-show="!form_submitted || formdata_name">Name</label>
              <div v-show="form_submitted && !formdata_name" class="invalid-feedback">Name is required</div>
              <div v-show="form_submitted && !formdata_name_is_valid" class="invalid-feedback">Name must be at least 4
                characters long</div>
            </div>

            <div class="form-floating my-3">
              <input type="text" class="form-control" v-model="formdata_command" name="formdata_command"
                id="formdata_name" placeholder="Command"
                :class="{ 'is-invalid': form_submitted && !formdata_command }" />
              <label for="formdata_command" v-show="!form_submitted || formdata_command">Command</label>
              <div v-show="form_submitted && !formdata_command" class="invalid-feedback">Command is required</div>
            </div>

            <div class="form-floating my-3">
              <textarea class="form-control" v-model="formdata_description" name="formdata_description"
                id="formdata_description" placeholder="Description" style="height: 200px;"></textarea>
              <label for="formdata_description">Description</label>
            </div>

            <div v-if="en_can_combine" class="form-check text-start my-3">
              <input class="form-check-input" type="checkbox" v-model="formdata_can_be_combined"
                name="formdata_can_be_combined" value="formdata_can_be_combined" id="formdata_can_be_combined"
                data-bs-toggle="tooltip" title="Check this box if the menu should be blocked">
              <label class="form-check-label" for="formdata_can_be_combined">
                Can be combinated (currently not working)
              </label>
            </div>

          </div>


          <div class="modal-footer">
            <button type="button" class="btn btn-secondary py-2" data-bs-dismiss="modal" @click="cleanupForm">Close</button>
            <button type="submit" class="btn btn-primary py-2" v-bind:disabled="form_is_loading">
              <span v-if="form_is_loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Update
            </button>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CropperModal from "@/components/module-common-cropperjs.vue";
import { Modal } from "bootstrap";
import { accountsService } from "@/components/ajax-common-account.js";
import { menusService } from "@/components/ajax-smartmcw-menu.js";
import { cafesService } from "@/components/ajax-smartmcw-cafe.js";

export default {
  name: 'UpdateMenuModal',
  components: {
    CropperModal,
  },
  data() {
    return {
      form_error: '',
      form_submitted: false,
      form_is_loading: false,

      formdata_org_id: null,
      formdata_id: null,
      formdata_name: null,
      formdata_description: null,
      formdata_command: null,
      formdata_can_be_combined: null,
      formdata_cover_image: null,
      formdata_cafes: [],

      is_ch_formdata_org_id: false,
      is_ch_formdata_name: false,
      is_ch_formdata_description: false,
      is_ch_formdata_command: false,
      is_ch_formdata_can_be_combined: false,
      is_ch_formdata_cover_image: false,
      is_ch_formdata_cafes: false,

      formdata_sel_index_cafe: "",

      availableCafes: [],
    };
  },
  props: {
    method_to_refresh: {
      type: Function,
      required: true
    },
    organizations: {
      type: Array,
      required: true
    },
    current_org: {
      type: Object,
      required: true
    },
    getSelItem: {
      type: Function,
      required: true
    },
    en_can_combine: {
        type: Boolean,
        default: function() {
            return false;
        },
        required: false,
    },
  },
  watch: {
    // see to setUpdatedImage()

    // formdata_org_id(newVal, oldVal) { if (newVal !== oldVal) this.is_ch_formdata_org_id = true; },
    // formdata_name(newVal, oldVal) { if (newVal !== oldVal) this.is_ch_formdata_name = true; },
    // formdata_description(newVal, oldVal) { if (newVal !== oldVal) this.is_ch_formdata_description = true; },
    // formdata_command(newVal, oldVal) { if (newVal !== oldVal) this.is_ch_formdata_command = true; },
    // formdata_can_be_combined(newVal, oldVal) { if (newVal !== oldVal) this.is_ch_formdata_can_be_combined = true; },
    // formdata_cafes(newVal, oldVal) { if (newVal !== oldVal) this.is_ch_formdata_cafes = true; },
  },
  methods: {
    setUpdatedImage(image) {
      this.formdata_cover_image = image;
      this.is_ch_formdata_cover_image = true;
    },
    async renderForm() {
      // this.organizations = this.getOrgs();
      // this.getAllOrgs();

      const data = this.getSelItem();

      this.formdata_org_id = data.org_id;
      await this.updateCafeList(); // after setup formdata_org_id

      this.formdata_id = data.id;
      this.formdata_name = data.name;
      this.formdata_description = data.description;
      this.formdata_command = data.command;
      this.formdata_can_be_combined = data.can_be_combined;
      this.formdata_cover_image = data.cover_image;

      this.addCafes(data.cafes);
      // this.formdata_created_at = data.created_at;
    },
    addCafes(items) {
      items.forEach(item => {
        this.addSelectedCafe(item);
      });
    },
    addCafe() {
      if (this.formdata_sel_index_cafe) {
        this.addSelectedCafe(this.formdata_sel_index_cafe);
        this.formdata_sel_index_cafe = "";
      }
    },
    addSelectedCafe(index_cafe) {
      const cafe = this.availableCafes.find(item => item.id === index_cafe);
      this.formdata_cafes.push(cafe);
      this.availableCafes = this.availableCafes.filter(item => item.id !== cafe.id);
    },
    removeCafe(cafeId) {
      const cafe = this.formdata_cafes.find(cafe => cafe.id === cafeId);
      this.formdata_cafes = this.formdata_cafes.filter(item => item.id !== cafeId);
      this.availableCafes.push(cafe);
      this.availableCafes.sort((a, b) => a.id - b.id);
      this.formdata_sel_index_cafe = "";
    },
    async updateCafeList() {
      if (this.formdata_org_id) {
        this.formdata_cafes = [];
        this.availableCafes = [];
        await this.getAllCafes(this.formdata_org_id);
      }
    },
    async setError(text) {
      this.error = text;
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.error = '';
    },
    trimInput() {
      this.formdata_name = this.formdata_name.trim();
    },
    closeModal() {
      const myModalEl = document.getElementById('updateModal')
      const modal = Modal.getInstance(myModalEl);
      modal.hide();

      this.cleanupForm();
    },
    uploadCover(menu_id) {
      if (this.formdata_cover_image) {
        menusService.uploadCover(menu_id, this.formdata_cover_image)
          .then(data => {
            console.log("Response after update cover: " + data);
            this.afterSubmit();
          })
          .catch(
            error => {
              if (error.body) {
                this.form_error = error.body;

                accountsService.checkIfLogin(error.body, true);

              } else {
                this.form_error = error.error;
              }
              console.error(this.form_error);
              this.form_is_loading = false;
            }
          );
      } else {
        this.afterSubmit();
      }
    },
    afterSubmit() {
      this.cleanupForm();
      this.closeModal();
      this.method_to_refresh(true);
      this.form_is_loading = false;
    },
    updateItem() {
      this.form_submitted = true;

      const {
        formdata_org_id,
        formdata_id,
        formdata_name,
        formdata_description,
        formdata_command,
        formdata_can_be_combined,
        formdata_cafes,
        // is_ch_formdata_org_id,
        // is_ch_formdata_name,
        is_ch_formdata_cover_image,
        // is_ch_formdata_description,
        // is_ch_formdata_command,
        // is_ch_formdata_can_be_combined,
        // is_ch_formdata_cafes,
      } = this;

      // здесь нужно добавить проверку, на предмет были ли какие-то значения изменены

      // if (!is_ch_formdata_org_id) formdata_org_id = null;
      // if (!is_ch_formdata_name) formdata_name = null;
      // if (!is_ch_formdata_description) formdata_description = null;
      // if (!is_ch_formdata_command) formdata_command = null;
      // if (!is_ch_formdata_can_be_combined) formdata_can_be_combined = null;
      // if (!is_ch_formdata_cafes) formdata_cafes = null;

      if (!formdata_name || !this.formdata_name_is_valid) {
        return;
      }


      const cafes = formdata_cafes.map(cafe => cafe.id);

      this.form_is_loading = true;
      menusService.update(formdata_id, formdata_name, formdata_description, formdata_command, formdata_can_be_combined, formdata_org_id, cafes)
        .then(data => {
          console.log("Response from menu: " + data);
          if (is_ch_formdata_cover_image) {
            console.log("is_ch_formdata_cover_image");
            this.uploadCover(formdata_id);
          } else {
            this.afterSubmit();
          }
        })
        .catch(
          error => {
            if (error.body) {
              this.form_error = error.body;

              accountsService.checkIfLogin(error.body, true);

            } else {
              this.form_error = error.error;
            }
            this.form_is_loading = false;
          }
        );
    },
    cleanupForm() {
      this.form_error = '';
      this.form_is_loading = false;
      this.form_submitted = false;

      this.formdata_id = null;
      this.formdata_name = null;
      this.formdata_description = null;
      this.formdata_can_be_combined = false;
      this.formdata_command = null;
      this.formdata_cover_image = null;
      this.formdata_cafes = [];

      this.is_ch_formdata_cover_image = false;

    },
    async getAllCafes(org_id = null) {
      await cafesService.getAll(false, org_id)
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.availableCafes = data;
        })
        .catch(
          async error => {
            if (error.body) {
              this.setError(error.body);

              accountsService.checkIfLogin(error.body, true);

              // if (error.body === "Too Many Requests") {
              // }
            } else {
              this.setError(error.error);
            }
          }
        );
    },

    // deprecated() {
    //   /* global bootstrap: false */
    //   (() => {
    //       'use strict'
    //       const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    //       tooltipTriggerList.forEach(tooltipTriggerEl => {
    //           new bootstrap.Tooltip(tooltipTriggerEl)
    //       })
    //   })();
    // },
  },
  computed: {
    organizations_wo_first() {
      return this.organizations.slice(1);
    },
    formdata_name_is_valid() {
      const trimmedName = this.formdata_name.trim();
      return trimmedName.length >= 4;
    }
  },
  created() {
  },
  mounted() {
    this.$refs.updateModal.addEventListener('shown.bs.modal', () => {
      this.renderForm();
    });
  }
};
</script>
<style scoped>
.form-control {
  width: 100%;
}

.input {
  border: none;
  outline: none;
  width: 100%;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tags .badge {
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
</style>
<template>
  <div class="modal fade" ref="modal" :id="modal_id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ header }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            @click="closeAction"></button>
        </div>

        <div class="modal-body">
          <div v-if="modal_error" class="alert alert-danger mb-3">{{ modal_error }}</div>
          <slot></slot>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary py-2" data-bs-dismiss="modal"
            @click="closeAction">Close</button>
          <button type="button" class="btn btn-primary py-2" v-bind:disabled="is_loading" @click="$emit('onSubmit')">
            <span v-if="is_loading" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            {{ btn_text }}</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: 'CommonModal',
  data() {
    return {
      modal_error: '',
      is_loading: false,
    };
  },
  props: {
    modal_id: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    btn_text: {
      type: String,
      required: true,
      default: function () {
        return "Action";
      },
    },
  },
  methods: {
    async setError(text) {
      this.modal_error = text;
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.clearError();
    },

    clearError() {
      this.modal_error = '';
    },

    _closeModal() {
      var myModalEl = document.getElementById(this.modal_id)
      var modal = Modal.getInstance(myModalEl);
      modal.hide();
    },

    closeAction() {
      this.clearError();
      this._closeModal();
      this.$emit('onClose');
    },

    async setIsLoading(value) {
      this.is_loading = value;
    },
  },
  mounted() {
    this.$refs.modal.addEventListener('shown.bs.modal', () => {
      this.$emit('onOpen');
    });
  }
};
</script>

<template>
  <main class="form-signin w-100 m-auto">

    <div class="d-flex justify-content-between mb-3">
      <a href="/">
        <img class="mb-3" :src="$site_logo" :alt="$site_name" height="57" style="margin-left:-5px;">
      </a>
      <changeLang add_sel_classes="w-auto mr-auto"/>
    </div>

    <div class="d-flex justify-content-between mb-3 align-items-end">
      <h1 class="h3 fw-normal">{{ $t("forgotpass.title") }}</h1>
    </div>

    <div v-if="request_was_sent">
      {{ $t("forgotpass.request_was_sent_mes") }}
    </div>

    <div v-if="!request_was_sent">
      <form @submit.prevent="handleSubmit">
        <div class="form-floating my-3">
          <input type="email" v-model="email" name="email" class="form-control rounded" id="email" placeholder="name@example.com"
            :class="{ 'is-invalid': submitted && !email }" />
          <label for="email" v-show="!submitted || email">{{ $t("forgotpass.enter_email") }}</label>
          <label v-show="submitted && !email" class="invalid-feedback" for="email">{{ $t("forgotpass.notice_email_required") }}</label>
        </div>

        <button class="btn btn-primary w-100 py-2" type="submit" v-bind:disabled="loading">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ $t("forgotpass.button") }}
        </button>
      </form>
    </div>

    <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
    <p class="mt-5 mb-3 text-body-secondary">{{ $site_copy }}</p>
  </main>
</template>

<script>
import router from "@/router";
import { accountsService } from "@/components/ajax-common-account.js";
import changeLang from "@/components/module-common-change_lang.vue";

// https://renatello.com/vue-js-body-class/
export default {
  name: 'AskChangePass',
  components: {
    changeLang
  },
  data() {
    return {
      user: '',
      email: '',
      submitted: false,
      loading: false,
      error: '',
      request_was_sent: false,
    };
  },
  methods: {
    signout() {
      accountsService.logout()
        .catch(
          error => {
            if (error.body) {
              this.error = error.body;
            } else {
              this.error = error.error;
            }

            if (this.error === "You are not logged") {
              this.error = '';
            }
          }
        );
    },
    getme() {
      accountsService.getMe()
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.user = data;
          // this.checkIfUserLogged(data);
        })
        .catch(
          error => {
            if (error.body) {
              this.error = error.body;
            } else {
              this.error = error.error;
            }

            if (this.error === "You are not logged") {
              this.error = '';
            }
          }
        );
    },
    checkIfUserLogged(user) {
      if (user) {
        router.push("/home");
      }
    },
    // handleSubmit(e) {
    handleSubmit() {
      this.submitted = true;
      const { email } = this;

      // console.log(remember_me);
      // stop here if form is invalid
      if (!(email)) {
        return;
      }

      // console.log(email, password);

      this.loading = true;
      accountsService.askChangePass(email)
        .then(data => {
          if (data === true) {
            this.request_was_sent = true;
          } else {
            console.error("Unexpected response format:", data);
            this.error = this.$i18n.t("forgotpass.error_from_server");
            this.loading = false;
          }
        })
        .catch(
          error => {
            if (error.body) {
              this.error = error.body;
            } else {
              // Обработка ошибки без тела ответа
              // console.error("Error:", error.error);
              this.error = error.error;
            }

            if (this.error === "Too Many Requests") {
              this.error = this.$i18n.t("forgotpass.error_too_many");
            }

            if (this.error === "User is not found.") {
              this.error = this.$i18n.t("resetpass.error_user_not_found");
            }

            if (this.error === "You are already logged") {
              router.push("/home");
            }

            this.loading = false;
          }
        );
    }
  },
  created() {
    this.getme();
    // this.signin();
  },
  mounted() {
    document.body.classList.add('d-flex', 'justify-content-center', 'align-items-center')
  },
  unmounted() {
    document.body.classList.remove('d-flex', 'justify-content-center', 'align-items-center')
  }
}
</script>
<style>
html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
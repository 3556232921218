import md5 from 'md5';

export const lsService = {
  getCached,
  deleteAllLS,
  setLSdirect,
  getLSdirect,
  unsetLS,
};

function deleteAllLS() {
  localStorage.clear();
}

function setLS(key, obj) {
  localStorage.setItem(key, JSON.stringify(obj));
}

function setLSdirect(key, item) {
  localStorage.setItem(key, item);
}

function unsetLS(key) {
  localStorage.removeItem(key);
  localStorage.removeItem(`${key}_updateTime`);
}

function getLSdirect(key) {
  return localStorage.getItem(key);
}

function getLS(key) {
  const serializedObject = localStorage.getItem(key);

  if (!serializedObject || serializedObject === "{}") {
    return null;
  }

  const _obj = JSON.parse(serializedObject);
  return _obj;
}

async function updateLS(key, func, ...args) {
  const backendObj = await func(...args);
  unsetLS(key);
  setLS(key, backendObj);
}

async function getCached(key, delay = 5, forced = false, func, ...args) {
  const currentTime = Math.floor(Date.now() / 1000);

  let cacheKey;
  if (args.length > 0) {
    const hash = md5(JSON.stringify(args));
    cacheKey = `${key}_${hash}`;
  } else {
    cacheKey = key;
  }

  const lastUpdateTime = parseInt(localStorage.getItem(`${cacheKey}_updateTime`) || '0');

  if (forced || currentTime - lastUpdateTime > delay) {
    await updateLS(cacheKey, func, ...args);
    localStorage.setItem(`${cacheKey}_updateTime`, currentTime.toString());
  }

  return getLS(cacheKey);
}

<template>
    <h3 class="mt-3">Sessions</h3>

    <button class="btn btn-primary py-2" @click="toggleShowSessions">{{ messageShowSessions }}</button>

    <div class="m-0 border-0 table-container">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col" class="align-top">User Agent</th>
                    <th scope="col" class="align-top">Issued (UTC)</th>
                    <th scope="col" class="align-top">Expires in</th>
                    <th scope="col" class="align-top">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="session in reversedSessions" :key="session.issued_at">
                    <td>{{ session.user_agent }}</td>
                    <td>{{ session.issued_at }}</td>
                    <td>{{ timeService.getDifference(session.expires_at) }}</td>
                    <td><button class="btn btn-sm btn-primary" v-bind:disabled="!timeService.isLeft(session.expires_at)"
                            @click="termSession(session.id)">Terminate</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { accountsService } from "@/components/ajax-common-account.js";
import { timeService } from "@/components/module-common-time.js";

export default {
    name: "SessionComponent",
    components: {
    },

    data() {
        return {
            timeService: timeService,
            sessions: [],
            isShowAllSessions: false,
            messageShowSessions: "Show All Sessions",
        }
    },
    props: {
        setError: {
            type: Function,
            required: false,
        },
    },
    computed: {
        reversedSessions: function () {
            return this.filteredSessions.slice().reverse(); // Инвертируем массив сеансов
        },
        filteredSessions: function () {
            if (this.isShowAllSessions)
                return this.sessions;
            else
                return this.sessions.filter(session => timeService.isLeft(session.expires_at));
        }
    },
    methods: {
        toggleShowSessions() {
            if (this.isShowAllSessions) {
                this.isShowAllSessions = false;
                this.messageShowSessions = "Show All Sessions";
            }
            else {
                this.isShowAllSessions = true;
                this.messageShowSessions = "Show Active Sessions";
            }
        },
        removeSessionById(id) {
            this.sessions = this.sessions.filter(session => session.id !== id);
        },
        termSession(id) {
            accountsService.termSession(id)
                .then(data => {
                    if (data) {
                        this.removeSessionById(id);
                    }
                    // if (this.$is_debug) console.log("Response message:", data);
                })
                .catch(
                    error => {
                        if (error.body) {
                            this.setError(error.body);
                        } else {
                            this.setError(error.error);
                        }

                        // if (this.error === "You are not logged") {
                        //   this.error = '';
                        // }
                    }
                );
        },
        getAllItems(force = false) {
            accountsService.getSessions(force)
                .then(data => {
                    if (data) {
                        this.sessions = data;
                    }
                    if (this.$is_debug) console.log("Response message:", data);
                })
                .catch(
                    error => {
                        if (error.body) {
                            this.setError(error.body);
                            accountsService.checkIfLogin(error.body, true);
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },
    },
    mounted() {
        this.getAllItems();
    },
}
</script>

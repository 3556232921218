import axios from 'axios';
import router from "@/router";

import { ssService } from './ajax-common-ss';
import { lsService } from './ajax-common-ls';

// import { timeService } from "@/components/module-common-time.js";

export const accountsService = {
  login,
  logout,
  getAll,
  getMe,
  register,
  authToken,
  confirmEmail,
  resendEmailCode,
  updatePass,
  askChangePass,
  termSession,
  unsetUserLS,
  getSessions,
  checkIfLogin,
};

function checkIfLogin(error, redirect=false) {
  if (error === "You are not logged") {
    unsetUserLS();
    if (redirect) {
      router.push("/");
    }
  }
}

function login(email, password, remember_me) {
  return axios.get('/api/v1/account/login', {
    withCredentials: true,
    params: {
      remember_me: remember_me
    },
    auth: {
      username: email,
      password: password
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

//         // Если сообщение "Failed to load resource" не генерируется самой библиотекой Axios, а отображается в консоли браузера, то перехватить это сообщение напрямую средствами JavaScript или Axios не получится.
//         // Данное сообщение отображается в консоли разработчика браузера, когда браузер не может загрузить запрашиваемый ресурс (URL или файл) из-за различных проблем, таких как неверный путь, проблемы с сетью или настройками сервера.
//         // Если вам нужно предотвратить отображение этого сообщения в консоли браузера, вы можете использовать специфичные методы или параметры в вашем коде, чтобы избежать ошибок загрузки ресурсов. Например, вы можете проверять наличие ресурса перед его загрузкой или обрабатывать ошибки загрузки с помощью обработчиков событий, предоставляемых браузером.
//         // Однако, перехватить это сообщение напрямую с помощью JavaScript или Axios не представляется возможным, так как оно генерируется браузером во время выполнения запроса.




function logout() {
  unsetUserLS();
  ssService.deleteAllSS();

  return axios.get('/api/v1/account/logout', {
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });

  // remove user from local storage to log user out
  // localStorage.removeItem("user");
}


function register(email, name, surname, password) {

  const data = {
    email: email,
    name: name,
    surname: surname,
    password: password
  };

  return axios.post('/api/v1/account/register', data, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });

  // remove user from local storage to log user out
  // localStorage.removeItem("user");
}

function unsetUserLS() {
  lsService.unsetLS('user');
}

async function getMe(forced = false) {
  return lsService.getCached("user", 3, forced, askBackendUser);
}


function askBackendUser() {
  // это возвращает промис
  return axios.get('/api/v1/account/me', {
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        const status = error.response.status;
        // Обработка ошибки с телом ответа
        throw { error, body, status };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function getAll(skip = 0, limit = 100) {
  return axios.get('/api/v1/users', {
    params: {
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function authToken(code) {
  return axios.get('/api/v1/account/auth/google', {
    params: {
      code: code
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function confirmEmail(code) {
  unsetUserLS();

  return axios.get('/api/v1/account/confirm', {
    params: {
      code: code
    },
    withCredentials: true
  })
    .then(response => {
      if (response.status == 201)
        return true;
      else
        return false;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function resendEmailCode(just_check) {
  return axios.get('/api/v1/account/resendemailcode', {
    params: {
      just_check: just_check
    },
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function updatePass(email, code, password) {
  const queryParams = {
    email: email,
    code: code,
  };

  const postData = {
    password: password
  };

  return axios.post('/api/v1/account/change_pass', postData, {
    params: queryParams,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function askChangePass(email) {
  return axios.get('/api/v1/account/ask_change_pass', {
    params: {
      email: email
    },
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function termSession(session_id) {
  unsetUserLS();

  const postData = {
    id: session_id
  };

  return axios.post('/api/v1/account/session', postData, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

async function getSessions(forced = false, show_expired = true) {
  return lsService.getCached("user", 3, forced, getSessions2, show_expired);
}

function getSessions2(show_expired = true) {
  return axios.get('/api/v1/account/sessions', {
    params: {
      show_expired: show_expired,
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

<template>
  <div>
    <h1>Menus</h1>
    <ErrorBlockComponent ref="errorComponent" />

    <SelectorOrgCafeComponent
      :setError="setError"
      :en_cafes="true"
      @onChangeOrgEv="org_id = $event"
      @onChangeOrgsEv="orgs = $event"
      @onChangeCafesEv="cafes = $event"
    />
    <MenuComponent
      :setError="setError"
      :formdata_org_id="org_id"
      :orgs="orgs"
      :cafes="cafes"
      :en_redactor=true
      :en_multiple_choice=true
      :en_refresh_but=true
      @onChange="menu_selected = $event"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorBlockComponent from '@/components/module-common-errorblock.vue'
import SelectorOrgCafeComponent from '@/components/module-smartmcw-selector_org_cafe.vue'
import MenuComponent from '@/components/module-smartmcw-showmenu.vue'

export default {
  name: "AdminMenus",

  components: {
    ErrorBlockComponent,
    SelectorOrgCafeComponent,
    MenuComponent,
  },

  data() {
    return {
      org_id: "",
      orgs: [],
      cafes: [],
    }
  },

  watch: {
  },
  methods: {
    async setError(text) {
      this.$refs.errorComponent.setError(text);
    },
  },
}
</script>

import { createI18n } from 'vue-i18n'

const messages = {
    en: {
        message: {
            promo_action: "Optimize the operation of your kitchen and improve the\u00A0quality of\u00A0service\u00A0provided",
            promo_text: "With SmartMCW, streamline your kitchen processes by effortlessly managing heating protocols for various dishes. Just like a\u00A0conductor orchestrating a\u00A0symphony, you can fine-tune the heat settings for each item on your menu, ensuring perfect execution every time.",
            dev_notice: "The site is under development, data is not saved, functionality may be limited.",
            signup_button: "Sign Up",
            home_button: "Enter into account",
            signin_link: "Sign In",
            signout_link: "Sign Out",
            promo_link: "Main",
            home_link: "Home",
        },
        signup: {
            signup_title: "Sign up",
            login_link: "Login",
            enter_email: "Email address",
            notice_email_required: "Email is required",
            enter_name: "Name",
            notice_name_required: "Name is required",
            enter_surname: "Surname",
            notice_surname_required: "Surname is required",
            enter_password: "Password",
            notice_password_required: "Password is required",
            notice_repeat_password: "Repeat Password",
            notice_passwords_dont_match: "Passwords do not match.",
            signup_button: "Sign up",
            or_label: "or",
            signup_google_button: "Sign up with Google",
            error_pass_too_small: "Password must be at least 8 characters long.",
            error_too_many: "Too many attempts, try later",
        },
        signin: {
            signin_title: "Sign in",
            signup_link: "Create an account",
            enter_email: "Email address",
            notice_email_required: "Email is required",
            enter_password: "Password",
            notice_password_required: "Password is required",
            rememberme_label: "Remember me",
            signin_button: "Sign in",
            or_label: "or",
            signin_google_button: "Sign in with Google",
            forgot_pass: "Forgot pass?",
            error_too_many: "Too many attempts, try later",
            error_wrong_pass: "Incorrect email or password",
        },
        verify: {
            verify_title: "Email confirmation required",
            notice_text: "A code has been sent to the specified email, check your mailbox and follow the link in the letter to confirm your account.",
            resend_button: "Send the code again after",
            code_notice: "The link in the email is only valid for 30 minutes after sending. Then you can resend the letter again.",
            user_not_logged: "To confirm your email, first log into your account.",
            login_button: "Login",
            invalid_code: "The code is invalid",
            email_ver: "Email was successful verified",
            gohome_button: "Go to Account",
            email_already_ver: "Email is already verified",
        },
        notfound: {
            message_404: "The page is not found",
            home_link: "Go to Main page",
        },
        resetpass: {
            title: "Update Password",
            enter_password: "Password",
            notice_password_required: "Password is required",
            notice_repeat_password: "Repeat Password",
            notice_passwords_dont_match: "Passwords do not match.",
            button_set_new_pass: "Update",
            when_user_is_logged: "You are already logged!",
            when_email_or_code_were_not_sent: "You have not sent email / code!",
            enter_email: "Email address",
            error_pass_too_small: "Password must be at least 8 characters long.",
            error_from_server: "Server error, try later",
            error_too_many: "Too many attempts, try later",
            error_user_not_found: "The user with this email address does not exist.",
        },
        forgotpass: {
            title: "Password recovery",
            enter_email: "Email address",
            notice_email_required: "Email is required",
            button: "Recover",
            error_from_server: "Server error, try later",
            request_was_sent_mes: "The email has been sent to the specified address with a link to reset your password.",
            error_too_many: "Too many attempts, try later",
        },
        googlecallback: {
            title: "Waiting response from Google",
            home_link: "Go to Main page",
            error_bad_code: "Bad Code!",
            error_code_has_not_been_passed: "The code has not received.",
            error_req_timeout: "An error likely occurred during login via Google. Please try logging in again.",
            error_too_many: "Too many attempts, try later",
        },
    },
    ru: {
        message: {
            promo_action: "Оптимизируйте работу вашей кухни и повысьте качество предоставляемого сервиса",
            promo_text: "С SmartMCW вы сможете рационализировать процессы на кухне, легко управляя протоколами нагрева для различных блюд. Как дирижер, организующий симфонию, вы сможете настраивать температурные режимы для каждой позиции вашего меню, обеспечивая идеальное исполнение каждый раз.",
            dev_notice: "Сайт находится в разработке, данные не сохраняются, функционал\u00A0мб\u00A0ограничен.",
            signup_button: "Регистрация",
            home_button: "Перейти в аккаунт",
            signin_link: "Войти",
            signout_link: "Выйти",
            promo_link: "Главная",
            home_link: "Аккаунт",
        },
        signup: {
            signup_title: "Регистрация",
            login_link: "Войти",
            enter_email: "Эл.\u00A0почта",
            notice_email_required: "Эл.\u00A0почта обязательна",
            enter_name: "Имя",
            notice_name_required: "Имя обязательно",
            enter_surname: "Фамилия",
            notice_surname_required: "Фамилия обязательна",
            enter_password: "Пароль",
            notice_password_required: "Пароль обязателен",
            notice_repeat_password: "Повторите пароль",
            notice_passwords_dont_match: "Пароли не совпадают.",
            signup_button: "Зарегистрироваться",
            or_label: "или",
            signup_google_button: "с помощью Google",
            error_pass_too_small: "Пароль должен быть хотя бы 8\u00A0символов",
            error_too_many: "Слишком много попыток, попробуйте позже",
        },
        signin: {
            signin_title: "Войти",
            signup_link: "Создать аккаунт",
            enter_email: "Эл.\u00A0почта",
            notice_email_required: "Эл.\u00A0почта обязательная",
            enter_password: "Пароль",
            notice_password_required: "Пароль обязателен",
            rememberme_label: "Запомнить меня",
            signin_button: "Войти",
            or_label: "или",
            signin_google_button: "Войти через Google",
            forgot_pass: "Забыли пароль?",
            error_too_many: "Слишком много попыток, попробуйте позже",
            error_wrong_pass: "Неправильный адрес эл. почты или пароль",
        },
        verify: {
            verify_title: "Необходимо подтверждение эл.\u00A0почты",
            notice_text: "На указанную почту было выслано письмо, проверьте почтовый ящик и пройдите по ссылке в письме для подтверждения учётной записи.",
            code_notice: "Ссылка в письме действительна только 30\u00A0минут после отправки. После вы можете переотправить письмо ещё раз.",
            resend_button: "Выслать письмо ещё раз через",
            user_not_logged: "Для того, чтобы подтвердить эл.\u00A0почту, сначала войдите в свою учётную запись.",
            login_button: "Войти",
            invalid_code: "Передан неверный код. Возможно ссылка в письме устарела.",
            email_ver: "Эл.\u00A0почта успешно подтверждена",
            gohome_button: "Перейти в аккаунт",
            email_already_ver: "Эл.\u00A0почта уже подтверждена",
        },
        notfound: {
            message_404: "Страница не\u00A0найдена",
            home_link: "Перейти на Главную",
        },
        resetpass: {
            title: "Обновить пароль",
            enter_password: "Пароль",
            notice_password_required: "Пароль обязателен",
            notice_repeat_password: "Повторите пароль",
            notice_passwords_dont_match: "Пароли не совпадают.",
            button_set_new_pass: "Обновить",
            when_user_is_logged: "Вы уже залогинены!",
            when_email_or_code_were_not_sent: "Вы не передали адрес эл. почты или код для сброса пароля!",
            enter_email: "Эл.\u00A0почта",
            error_pass_too_small: "Пароль должен быть хотя бы 8\u00A0символов",
            error_from_server: "Ошибка сервера, попробуйте позже",
            error_too_many: "Слишком много попыток, попробуйте позже",
            error_user_not_found: "Пользователя с данным электронным адресом не существует.",
        },
        forgotpass: {
            title: "Восстановление пароля",
            enter_email: "Эл.\u00A0почта",
            notice_email_required: "Эл.\u00A0почта обязательная",
            button: "Восстановить",
            error_from_server: "Ошибка сервера, попробуйте позже",
            request_was_sent_mes: "На указанную почту было выслано письмо с ссылкой для восстановления пароля.",
            error_too_many: "Слишком много попыток, попробуйте позже",
        },
        googlecallback: {
            title: "Ждём ответ от Google",
            home_link: "Перейти на Главную страницу",
            error_bad_code: "Неверный код!",
            error_code_has_not_been_passed: "Код не был получен.",
            error_req_timeout: "Скорее всего произошла ошибка при входе через Google, попробуйте залогиниться ещё раз.",
            error_too_many: "Слишком много попыток, попробуйте позже",
        },
    }
}

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})

export default i18n
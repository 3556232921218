<template>
  <div class="modal fade" ref="createModal" id="createModal" tabindex="-1" aria-labelledby="createModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="createItem">

          <div class="modal-header">
            <h5 class="modal-title" id="createModalLabel">New Mcw</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              @click="cleanupForm"></button>
          </div>
          <div class="modal-body">
            <div v-if="form_error" class="alert alert-danger mb-3">{{ form_error }}</div>

            <!-- https://getbootstrap.com/docs/5.0/forms/select/ -->
            <div class="form-floating">
              <select class="form-select" id="orgSelect" v-model="formdata_org_id" @change="updateCafeList">
                <option value="" disabled selected hidden>{{ organizations.length === 0 ? 'Create an organization first' : 'Choose an organization' }}</option>
                <option v-for="org in organizations" v-bind:key="org.id" v-bind:value="org.id">{{ org.name }} ({{ org.id }})</option>
              </select>
              <label for="orgSelect">Organization:</label>
            </div>

            <div class="form-floating my-3">
              <select class="form-select" id="cafeSelect" v-model="formdata_cafe_id" :disabled="availableCafes.length === 0">
                <option value="" disabled selected hidden>{{ availableCafes.length === 0 ? 'There are no available cafes in the organization' : 'Choose cafes' }}</option>
                <option v-for="cafe in availableCafes" :key="cafe.id" :value="cafe.id">{{ cafe.name }} ({{ cafe.id }})</option>
              </select>
              <label for="cafeSelect">Cafes:</label>
            </div>

            <div class="form-floating">
              <input type="text" class="form-control" v-model="formdata_name" name="formdata_name" id="formdata_name"
                placeholder="Name" :class="{ 'is-invalid': form_submitted && (!formdata_name || !formdata_name_is_valid) }" />
              <label for="formdata_name" v-show="!form_submitted || formdata_name">Name</label>
              <div v-show="form_submitted && !formdata_name" class="invalid-feedback">Name is required</div>
              <div v-show="form_submitted && !formdata_name_is_valid" class="invalid-feedback">Name must be at least 4
                characters long</div>
            </div>

            <div class="form-floating my-3">
              <textarea class="form-control" v-model="formdata_description" name="formdata_description" id="formdata_description"
                placeholder="Description" style="height: 200px;"></textarea>
              <label for="formdata_description">Description</label>
            </div>

            <div class="form-floating my-3">
              <input type="text" class="form-control" v-model="formdata_version" name="formdata_version" id="formdata_version"
                placeholder="Version" />
              <label for="formdata_version">Version</label>
            </div>

            <div class="form-floating my-3">
              <input type="text" class="form-control" v-model="formdata_secret_key" name="formdata_secret_key" id="formdata_secret_key"
                placeholder="Secret key" />
              <label for="formdata_secret_key">Secret key</label>
            </div>

            <div class="form-check text-start my-3">
              <input class="form-check-input" type="checkbox" v-model="formdata_is_blocked" name="formdata_is_blocked"
                value="formdata_is_blocked" id="formdata_is_blocked" data-bs-toggle="tooltip"
                title="Check this box if the mcw should be public">
              <label class="form-check-label" for="formdata_is_blocked">
                Is blocked
              </label>
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary py-2" data-bs-dismiss="modal"
              @click="cleanupForm">Close</button>
            <button type="submit" class="btn btn-primary py-2" v-bind:disabled="form_is_loading"><span v-if="form_is_loading"
                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Create</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { accountsService } from "@/components/ajax-common-account.js";
import { mcwsService } from "@/components/ajax-smartmcw-mcw.js";
import { cafesService } from "@/components/ajax-smartmcw-cafe.js";

export default {
  name: 'CreateMcwModal',
  data() {
    return {
      formdata_name: null,
      formdata_description: null,
      formdata_version: null,
      formdata_secret_key: null,
      formdata_is_blocked: false,
      formdata_cafe_id: '',
      formdata_org_id: '',

      form_submitted: false,
      form_error: '',
      modal_is_open: true,
      form_is_loading: false,

      availableCafes: [],
    };
  },
  props: {
    method_to_refresh: {
      type: Function,
      required: true
    },
    organizations: {
      type: Array,
      required: true
    },
    current_org: {
      type: Object,
      required: true
    },
  },
  methods: {
    async setError(text) {
      this.error = text;
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.error = '';
    },
    trimInput() {
      this.form_name = this.form_name.trim();
    },
    closeModal() {
      this.form_error = '';
      var myModalEl = document.getElementById('createModal')
      var modal = Modal.getInstance(myModalEl);
      modal.hide();
    },
    createItem() {
      this.form_submitted = true;
      const {
        formdata_name,
        formdata_description,
        formdata_is_blocked,
        formdata_version,
        formdata_secret_key,
        formdata_cafe_id,
        formdata_org_id,
      } = this;

      // console.log(remember_me);
      // stop here if form is invalid
      if (!formdata_name || !this.formdata_name_is_valid) {
        return;
      }

      // console.log(email, password);

      this.form_is_loading = true;
      mcwsService.create(
        formdata_name,
        formdata_description,
        formdata_is_blocked,
        formdata_version,
        formdata_secret_key,
        formdata_cafe_id,
        formdata_org_id,
      )
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          // router.push("/home");
          this.cleanupForm();
          this.closeModal();
          this.method_to_refresh(true);
          this.form_is_loading = false;
        })
        .catch(
          error => {
            if (error.body) {
              this.form_error = error.body;
            } else {
              this.form_error = error.error;
            }
            this.form_is_loading = false;
          }
        );
    },
    updateCafeList() {
      if (this.formdata_org_id) {
        this.availableCafes = [];
        this.getAllCafes(this.formdata_org_id);
      }
    },
    getAllCafes(org_id = null) {
      cafesService.getAll(false, org_id)
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.availableCafes = data;
        })
        .catch(
          async error => {
            if (error.body) {
              this.setError(error.body);

              accountsService.checkIfLogin(error.body, true);

              // if (error.body === "Too Many Requests") {
              // }
            } else {
              this.setError(error.error);
            }
          }
        );
    },
    cleanupForm() {
      this.form_error = '';
      this.form_submitted = false;
      this.form_is_loading = false;

      this.formdata_name = null,
      this.formdata_description = null,
      this.formdata_version = null,
      this.formdata_secret_key = null,
      this.formdata_is_blocked = false,
      this.formdata_cafe_id = '',
      this.formdata_org_id = '',
      this.formdata_name = '';
    },
    // deprecated() {
    //   /* global bootstrap: false */
    //   (() => {
    //       'use strict'
    //       const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    //       tooltipTriggerList.forEach(tooltipTriggerEl => {
    //           new bootstrap.Tooltip(tooltipTriggerEl)
    //       })
    //   })();
    // },
  },
  computed: {
    formdata_name_is_valid() {
      const trimmedName = this.formdata_name.trim();
      return trimmedName.length >= 4;
    }
  },
  created() {
  },
  mounted() {
    this.$refs.createModal.addEventListener('shown.bs.modal', () => {
      if (this.current_org) {
        this.formdata_org_id = this.current_org;
        this.updateCafeList();
      }
    });
  }
};
</script>

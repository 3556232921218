<template>
    <div v-if="listItems.length === 0" class="mt-3"><b>There are no available mcws in the selected cafe.</b></div>

    <div class="row me-2 mb-1 justify-content-around align-items-center" v-for="device in listItems"
        :key="device.id">

        <div class="col-auto d-flex align-items-center">
            <img class="mr-2" src="/svg/microwave-oven.svg" style="height: 42px;" alt="Device icon">
            <div class="badge mr-2" :class="getStateBadge(device.state)"><span class="small">{{ getStateMes(device.state) }}</span></div>
            <div class="fw-bold">{{ device.name }}</div>
            <!-- <div style="height: 17px;">
                <span class="small me-2">hw: {{ device.hw_ver }}</span>
                <span class="small">sw: {{ device.sw_ver }}</span>
            </div> -->
        </div>

        <div class="col flex-grow-1 p-0 progress">
            <!-- https://getbootstrap.com/docs/5.0/components/progress/ -->
            <div class="progress-bar progress-bar-striped" :class="getMcwProgressClasses(device.id)" :style="{ width: device.progress + '%'}" role="progressbar" :aria-valuenow="device.progress || 100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <div class="col-auto text-end">
            <button class="btn btn-primary py-2" @click="go(device)"
                v-if="!isMcwBusy(device.id)"
                v-bind:disabled="!there_is_menu || !isActive(device.state)">Go</button>

            <button class="btn btn-danger py-2" @click="stop(device)"
                v-if="isMcwBusy(device.id)"
                v-bind:disabled="!isActive(device.state)">Stop</button>
        </div>
    </div>
</template>

<script>
import { accountsService } from "@/components/ajax-common-account.js";
import { mcwsService } from "@/components/ajax-smartmcw-mcw.js";

export default {
    name: "McwsComponent",
    data() {
        return {
            org_id: null,
            listItems: [],
            btn_refresh_col_loading: false,
            there_is_menu: false,
            busyMcws: [],
            mcw_update_timers: [],
        }
    },
    props: {
        setError: {
            type: Function,
            required: false,
        },
        cafe_id: {
            type: String,
            required: true
        },
        menu: {
            // возможно лучше передать объект меню,
            // чтобы можно было расчитать время для прогресс бара
            type: Object,
            required: true
        },
        resetSelectedMenu: {
            type: Function,
            required: true
        },
    },
    watch: {
        async cafe_id() {
            this.listItems = [];

            if (this.cafe_id !== "" && this.cafe_id !== null) {
                await this.getAllItems();
                this.subcribeMcwsState();
            }
        },
        menu() {
            // console.log(this.menu !== null);
            this.there_is_menu = this.menu !== null;
        },
    },
    computed: {
    },
    methods: {
        unsubcribeMcwsState() {
            this.mcw_update_timers.forEach(function(item) {
                clearTimeout(item);
            });
            this.mcw_update_timers = [];
        },
        subcribeMcwsState() {
            this.unsubcribeMcwsState();

            const self = this;
            this.listItems.forEach(function(item) {
                self.getMcwState(item.id);
                const timer = setInterval(() => {
                    self.getMcwState(item.id);
                }, 5000);
                self.mcw_update_timers.push(timer);
            });
        },
        isActive(state) {
            switch (state) {
                case undefined:
                    return false;
                case "st_offline":
                    return false;
                case "st_ready":
                    return true;
                default:
                    return false;
            }
        },
        getStateMes(state) {
            switch (state) {
                case undefined:
                    return "Connecting";
                case "st_offline":
                    return "Offline";
                case "st_ready":
                    return "Online";
                case "backend_error":
                    return "Connecting";
                default:
                    return state;
                    // return "Unknown";
            }
        },
        getStateBadge(state) {
            switch (state) {
                case undefined:
                    return "bg-warning";
                case "st_offline":
                    return "bg-danger";
                case "st_ready":
                    return "bg-success";
                case "backend_error":
                    return "bg-warning";
                default:
                    return "bg-primary";
            }
        },
        changeState(mcw_id, state) {
            const index = this.listItems.findIndex(item => item.id === mcw_id);
            if (index !== -1) {
                this.listItems[index].state = state;
            }
            else {
                console.log("Strange situation, it should be invetigated.");
            }
        },
        async startMcw(mcw_id, menu_id) {
            await mcwsService.startMcw(mcw_id, menu_id)
                .then(data => {
                    if (this.$is_debug) console.log("Response message:", data);
                })
                .catch(
                    async error => {
                        if (error.body) {
                            console.error("error.body: " + error.body);
                            console.error("error.error: " + error.error);

                            this.setError(error.body);

                            accountsService.checkIfLogin(error.body, true);

                            // if (error.body === "Too Many Requests") {
                            //     this.btn_refresh_col_is_active = false;
                            //     await new Promise(resolve => setTimeout(resolve, 5000));
                            //     this.btn_refresh_col_is_active = true;
                            // }
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },
        async stopMcw(mcw_id) {
            await mcwsService.stopMcw(mcw_id)
                .then(data => {
                    if (this.$is_debug) console.log("Response message:", data);
                })
                .catch(
                    async error => {
                        if (error.body) {
                            console.error("error.body: " + error.body);
                            console.error("error.error: " + error.error);

                            this.setError(error.body);

                            accountsService.checkIfLogin(error.body, true);

                            // if (error.body === "Too Many Requests") {
                            //     this.btn_refresh_col_is_active = false;
                            //     await new Promise(resolve => setTimeout(resolve, 5000));
                            //     this.btn_refresh_col_is_active = true;
                            // }
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },
        getMcwState(mcw_id) {
            mcwsService.getState(mcw_id)
                .then(data => {
                    this.changeState(mcw_id, data.state);
                    // if (this.$is_debug) console.log("Response message:", data);
                })
                .catch(
                    async error => {
                        this.changeState(mcw_id, "backend_error");
                        if (error.body) {
                            console.error("error.body: " + error.body);
                            console.error("error.error: " + error.error);

                            this.setError(error.body);

                            accountsService.checkIfLogin(error.body, true);

                            // if (error.body === "Too Many Requests") {
                            //     this.btn_refresh_col_is_active = false;
                            //     await new Promise(resolve => setTimeout(resolve, 5000));
                            //     this.btn_refresh_col_is_active = true;
                            // }
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },
        // setTimer() {
        //     this.is_active_resend = false;
        //     if (this.timer !== null)
        //         clearTimeout(this.timer);

        //     this.timer = setTimeout(() => {
        //         this.is_active_resend = true;
        //     }, this.time_left_to_resend * 1000);

        //     this.startCountdown();
        // },
        // startCountdown() {
        //     if (this.timer2 !== null)
        //         clearTimeout(this.timer2);
        //     this.timer2 = setInterval(() => {
        //         if (this.time_left_to_resend > 0) {
        //             this.time_left_to_resend--;
        //         } else {
        //             clearInterval(this.timer2);
        //         }
        //     }, 1000);
        // },
        getMcwProgressClasses(deviceId) {
            if (this.isMcwBusy(deviceId)) {
                return "progress-bar-animated bg-primary";
            }
            else {
                return "bg-secondary";
            }
        },
        isMcwBusy(deviceId) {
            return this.busyMcws.find(item => item === deviceId);
        },
        leaseMcw(device) {
            if (device.timer !== null) clearTimeout(device.timer);

            device.duration = 0;
            device.time_left = 0;
            device.progress = 0;

            this.busyMcws = this.busyMcws.filter(item => item !== device.id);
        },
        getDuration(commandJson) {
            let command;
            try {
                command = JSON.parse(commandJson);
            } catch (error) {
                return "?";
            }

            if (!Array.isArray(command)) {
                return "?";
            }

            let totalDurationSeconds = 0;
            for (const item of command) {
                if (typeof item === 'object' && 'duration' in item) {
                    totalDurationSeconds += item.duration;
                }
            }

            return totalDurationSeconds;
        },
        setMenuTimer(device) {
            device.duration = this.getDuration(this.menu.command);
            device.duration = device.duration * 10;
            device.time_left = device.duration;

            if (device.timer !== null)
                clearTimeout(device.timer);

            const self = this;
            device.timer = setInterval(() => {

                if (device.time_left > 0) {
                    device.time_left--;
                    device.progress = (device.duration - device.time_left) * 100 / device.duration;
                    // console.log(device.progress);
                } else {
                    self.leaseMcw(device);
                }
            }, 100);
        },
        async go(device) {
            console.log("Go Pressed! dev id: " + device.id + " menu_id: " + this.menu.id);
            await this.startMcw(device.id, this.menu.id);
            this.busyMcws.push(device.id);
            this.resetSelectedMenu();
            this.setMenuTimer(device);
        },
        async stop(device) {
            const confirmation = window.confirm("Are you sure that you wanna stop the task?");

            if (confirmation) {
                console.log("Stop Pressed! dev id: " + device.id);
                await this.stopMcw(device.id);
                this.leaseMcw(device);
            }
        },
        async getAllItems(force = false) {
            this.btn_refresh_col_loading = true;
            await mcwsService.getAll(force, this.org_id, this.cafe_id)
                .then(data => {
                    if (this.$is_debug) console.log("Response message:", data);

                    this.listItems = data;
                    this.listItems.sort((a, b) => {
                        return new Date(a.created_at) - new Date(b.created_at);
                    });

                    this.btn_refresh_col_loading = false;
                })
                .catch(
                    async error => {
                        this.btn_refresh_col_loading = false;

                        if (error.body) {
                            this.setError(error.body);

                            accountsService.checkIfLogin(error.body, true);

                            if (error.body === "Too Many Requests") {
                                this.btn_refresh_col_is_active = false;
                                await new Promise(resolve => setTimeout(resolve, 5000));
                                this.btn_refresh_col_is_active = true;
                            }
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },
    },
    mounted() {
        if (this.cafe_id !== "" && this.cafe_id !== null) {
            this.getAllItems();
        }
    },
}
</script>
import axios from 'axios';

import { ssService } from './ajax-common-ss';

export const orgsService = {
  getAll,
  create,
  update,
  remove,
  checkIf500,
};

function checkIf500(error) {
  if (error === "Internal Server Error") {
    return true;
  } else {
    return false;
  }
}

async function getAll(forced=false, skip=0, limit=100) {
  return ssService.getCached("orgs", 5, forced, getAllOld, skip, limit);
}

async function getAllOld(skip=0, limit=100) {
  return axios.get('/api/v1/org/', {
    params: {
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

function create(name, description, is_blocked) {
  return axios.post('/api/v1/org/', {
    withCredentials: true,

    name: name,
    description: description,
    is_blocked: is_blocked
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function update(id, name, description, is_blocked) {
  return axios.patch('/api/v1/org/' + id, {
    withCredentials: true,

    name: name,
    description: description,
    is_blocked: is_blocked,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function remove(id) {
  return axios.delete('/api/v1/org/' + id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

<template>
  <titleComponent :title="$site_name"></titleComponent>
  <router-view />
</template>

<script>
import titleComponent from '@/components/module-common-title.vue';

export default {
  components: {
    titleComponent
  },
  data() {
    return {
      title: this.$site_name
    }
  }
}
</script>


<template>
  <div>
    <ErrorBlockComponent ref="errorComponent" />

    <SelectorCafeComponent
      :setError="setError"
      @onChangeCafeEv="cafe_id = $event"
    />

    <McwsComponent
      :setError="setError"
      :cafe_id="cafe_id"
      :menu="menu_selected"
      :resetSelectedMenu="unSelectAllItem"
    />
    <hr />
    <MenuComponent
      :setError="setError"
      ref="menuComponent"
      :formdata_cafe_id="cafe_id"
      @onChange="menu_selected = $event"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorBlockComponent from '@/components/module-common-errorblock.vue'
import SelectorCafeComponent from '@/components/module-smartmcw-selector_cafe.vue'
import McwsComponent from '@/components/module-smartmcw-showmcw.vue'
import MenuComponent from '@/components/module-smartmcw-showmenu.vue'

export default {
  name: "BaristaWorkspace",

  components: {
    ErrorBlockComponent,
    SelectorCafeComponent,
    McwsComponent,
    MenuComponent,
  },

  data() {
    return {
      error: '',

      cafe_id: "",
      menu_selected: null,
    }
  },
  methods: {
    unSelectAllItem() {
      this.$refs.menuComponent.unSelectAllItem();
    },
    async setError(text) {
      this.$refs.errorComponent.setError(text);
    },
  },
}
</script>

import axios from 'axios';

import { ssService } from './ajax-common-ss';

export const menusService = {
  getAll,
  create,
  remove,
  update,
  uploadCover,
};

async function getAll(forced = false, org_id = null, cafe_id = null, skip = 0, limit = 100) {
  return ssService.getCached("menus", 5, forced, getAllOld, org_id, cafe_id, skip, limit);
}

async function getAllOld(org_id=null, cafe_id=null, skip=0, limit=100) {
  return axios.get('/api/v1/menu/', {
    params: {
      org_id: org_id,
      cafe_id: cafe_id,
      skip: skip,
      limit: limit,
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

function create(name, description, command, can_be_combined, org_id, cafes) {
  return axios.post('/api/v1/menu/', {
    withCredentials: true,

    name: name,
    description: description,
    command: command,
    can_be_combined: can_be_combined,
    org_id: org_id,
    cafes: cafes
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

function remove(id) {
  return axios.delete('/api/v1/menu/' + id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function update(id, name=null, description=null, command=null, can_be_combined=null, org_id=null, cafes=null) {
  return axios.patch('/api/v1/menu/' + id, {
    withCredentials: true,

    name: name,
    description: description,
    command: command,
    can_be_combined: can_be_combined,
    org_id: org_id,
    cafes: cafes,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function uploadCover(menu_id, base64Image) {
  // https://axios-http.com/docs/multipart
  const base64Data = base64Image.split(',')[1];

  // Преобразуйте данные изображения из base64 в бинарный формат
  const imageData = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(imageData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < imageData.length; i++) {
      uint8Array[i] = imageData.charCodeAt(i);
  }

  // Создайте Blob из бинарных данных
  const blob = new Blob([uint8Array], { type: 'image/jpeg' });

  // Создайте FormData и добавьте Blob в него
  const formData = new FormData();
  formData.append('file', blob, 'image.jpg');

  console.log("entering uploadCover");
  return axios.post('/api/v1/menu/' + menu_id + '/uploadcover', formData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

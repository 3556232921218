import { ssService } from './ajax-common-ss';
import axios from 'axios';

export const groupsService = {
  attach,
  deattach,
  getUserGroups,
  decline,
  accept,
};

async function decline(group_id) {
  return axios.post('/api/v1/groups/decline/' + group_id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

async function accept(group_id) {
  return axios.post('/api/v1/groups/accept/' + group_id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}



async function getUserGroups(forced = false, skip = 0, limit = 100) {
  return ssService.getCached("user_groups", 5, forced, getUserGroupsDirect, skip, limit);
}


async function getUserGroupsDirect(skip = 0, limit = 100) {
  return axios.get('/api/v1/groups/', {
    params: {
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function attach(user_email, groups) {
  if (!groups || groups.length === 0) {
    return;
    // return Promise.reject(new Error("The groups list is empty."));
  }

  return axios.post('/api/v1/groups/attach', {
    withCredentials: true,
    user_email: user_email,
    group_ids: groups,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function deattach(user_email, groups) {
  if (!groups || groups.length === 0) {
    return;
    // return Promise.reject(new Error("The groups list is empty."));
  }

  return axios.post('/api/v1/groups/deattach', {
    withCredentials: true,
    user_email: user_email,
    group_ids: groups,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

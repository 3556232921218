<template>
  <main class="form-verify w-100 m-auto">

    <div class="d-flex justify-content-between mb-3">
      <a href="/">
        <!-- width="72" -->
        <img class="mb-3" :src="$site_logo" :alt="$site_name" height="57" style="margin-left:-5px;">
      </a>
      <changeLang add_sel_classes="w-auto mr-auto"/>
    </div>

    <h1 class="h3 fw-normal">{{ $t("verify.verify_title") }}</h1>

    <div v-if="user_is_not_logged">
      <p>{{ $t("verify.user_not_logged") }}</p>

      <button class="btn btn-primary w-100 py-2" type="submit" @click="goLogin">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t("verify.login_button") }}
      </button>
    </div>

    <div v-if="code_was_not_sent">
      <p>{{ $t("verify.notice_text") }}</p>
      <p>{{ $t("verify.code_notice") }}</p>

      <button class="btn btn-primary w-100 py-2" type="submit" v-bind:disabled="!is_active_resend" @click="resendEmailCode(false)">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t("verify.resend_button") }} {{ time_left_to_resend }}
      </button>

      <!-- <div class="mt-3">
        <a href="#" @click="goBack">Go Back</a>
      </div> -->
    </div>


    <div v-if="code_is_invalid">
      <p>{{ $t("verify.invalid_code") }}</p>
      <p>{{ $t("verify.code_notice") }}</p>

      <button class="btn btn-primary w-100 py-2" type="submit" v-bind:disabled="!is_active_resend" @click="resendEmailCode(false)">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t("verify.resend_button") }} {{ time_left_to_resend }}
      </button>
    </div>

    <div v-if="email_is_verified">
      <p>{{ $t("verify.email_ver") }}</p>

      <button class="btn btn-primary w-100 py-2" type="submit" @click="goHome">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t("verify.gohome_button") }}
      </button>
    </div>

    <div v-if="email_already_verified">
      <p>{{ $t("verify.email_already_ver") }}</p>

      <button class="btn btn-primary w-100 py-2" type="submit" @click="goHome">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t("verify.gohome_button") }}
      </button>
    </div>


    <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
    <p class="mt-5 mb-3 text-body-secondary">{{ $site_copy }}</p>
  </main>
</template>

<script>
import router from "@/router";
import { accountsService } from "@/components/ajax-common-account.js";
import changeLang from "@/components/module-common-change_lang.vue";

export default {
  name: 'EmailRedirect',
  components: {
    changeLang
  },
  data() {
    return {

      code_was_not_sent: false,
      code_is_invalid: false,

      user_is_not_logged: true,
      email_already_verified: false,
      email_is_verified: false,

      error: null,
      is_already_redirected: false,

      time_left_to_resend: 0,
      timer: null,
      timer2: null,
      is_active_resend: false,
    };
  },
  methods: {
    resendEmailCode(just_check) {
      accountsService.resendEmailCode(just_check)
        .then(data => {
          if (data.verified === true) {
            if (!just_check)
              this.email_is_verified = true;
          } else {
            this.time_left_to_resend = data.time_left;
            console.log(this.time_left_to_resend);
            this.setTimer();
          }
        })
        .catch(
          error => {
            if (error.body) {
              const responseBody = error.body;
              if (responseBody !== "You are not logged")
                this.error = responseBody;
            } else {
              this.error = error;
            }
          }
        );
    },
    sendCodeToServer(code) {
      accountsService.confirmEmail(code)
        .then(data => {
          if (data === true) {
            this.email_is_verified = true;
          } else {
            this.code_is_invalid = true;
          }
        })
        .catch(
          error => {
            this.code_is_invalid = true;

            if (error.body) {
              const responseBody = error.body;
              if (responseBody !== "The code is invalid.")
                if (!responseBody.includes("validation error for Request"))
                  this.error = responseBody;
            } else {
              this.error = error;
            }
          }
        );
    },
    getMe() {
      accountsService.getMe()
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.changeUserStatus(data);
        })
        .catch(
          error => {
            if (error.body) {
              const responseBody = error.body;

              if (responseBody === "You are not logged") {
                this.user_is_not_logged = true;
              } else {
                this.error = responseBody;
              }
            } else {
              this.error = error.error;
            }
          }
        );
    },
    changeUserStatus(data) {
      this.user_is_not_logged = false;
      if (data.email_is_verified) {
        this.email_already_verified = true;
      }
      else {
        // check that code was set
        const code = this.$route.query.code;
        if (code != null)
        {
          this.sendCodeToServer(code);
        }
        else
        {
          this.code_was_not_sent = true;
        }
      }

      // if (user) {
      //   console.log("User is logged!");
      //   // router.push("/home");
      // }
    },
    goLogin() {
      // router.push("/login");
      // it doesn't send reffer
      window.location.href = "/login";
    },
    goHome() {
      router.push("/home");
    },
    setTimer() {
      this.is_active_resend = false;
      if (this.timer !== null)
        clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.is_active_resend = true;
      }, this.time_left_to_resend * 1000);

      this.startCountdown();
    },
    startCountdown() {
      if (this.timer2 !== null)
        clearTimeout(this.timer2);
      this.timer2 = setInterval(() => {
        if (this.time_left_to_resend > 0) {
          this.time_left_to_resend--;
        } else {
          clearInterval(this.timer2);
        }
      }, 1000);
    },
    goCover() {
      // setTimeout(() => {
      //   if (!this.is_already_redirected)
      //     this.$router.push('/');
      // }, 5000);
    },
    // checkIfUserLogged(message) {
    //   if (message === "Welcome to the dark side, we have cookies") {
    //     this.is_already_redirected = true;
    //     router.push("/home");
    //   }
    // },
    goBack() {
      this.$router.go(-1);
    }
  },
  created() {

  },
  mounted() {
    this.resendEmailCode(true);
    this.getMe();
    document.body.classList.add('d-flex', 'justify-content-center', 'align-items-center')
  },
  unmounted() {
    document.body.classList.remove('d-flex', 'justify-content-center', 'align-items-center')
  }
}
</script>
<style>
html,
body {
  height: 100%;
}

.form-verify {
  max-width: 330px;
  padding: 1rem;
}
</style>
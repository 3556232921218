<template>
  <div>
    <h1>Orgs</h1>
    <ErrorBlockComponent ref="errorComponent" />
    <AdminOrgsComponent :setError="setError" />
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorBlockComponent from '@/components/module-common-errorblock.vue'
import AdminOrgsComponent from '@/components/module-smartmcw-showorg.vue'

export default {
  name: "AdminOrgs",
  components: {
    ErrorBlockComponent,
    AdminOrgsComponent,
  },
  methods: {
    async setError(text) {
      this.$refs.errorComponent.setError(text);
    },
  },
}
</script>

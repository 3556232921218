import md5 from 'md5';

export const ssService = {
  getCached,
  deleteAllSS,
  setSSdirect,
  getSSdirect,
  unsetSS,
};

function deleteAllSS() {
  sessionStorage.clear();
}

function setSS(key, obj) {
  sessionStorage.setItem(key, JSON.stringify(obj));
}

function setSSdirect(key, item) {
  sessionStorage.setItem(key, item);
}

function unsetSS(key) {
  sessionStorage.removeItem(key);
}

function getSSdirect(key) {
  return sessionStorage.getItem(key);
}

function getSS(key) {
  const serializedObject = sessionStorage.getItem(key);

  if (!serializedObject || serializedObject === "{}") {
    return null;
  }

  const _obj = JSON.parse(serializedObject);
  return _obj;
}

async function updateSS(key, func, ...args) {
  const backendObj = await func(...args);
  unsetSS(key);
  setSS(key, backendObj);
}

async function getCached(key, delay = 5, forced = false, func, ...args) {
  const currentTime = Math.floor(Date.now() / 1000);
  const hash = md5(JSON.stringify(args));
  const cacheKey = `${key}_${hash}`;

  const lastUpdateTime = parseInt(sessionStorage.getItem(`${cacheKey}_updateTime`) || '0');

  if (forced || currentTime - lastUpdateTime > delay) {
    await updateSS(cacheKey, func, ...args);
    sessionStorage.setItem(`${cacheKey}_updateTime`, currentTime.toString());
  }

  return getSS(cacheKey);
}

<template>
    <div v-if="error" class="alert alert-danger alert-dismissible mb-3">
        <div>{{ error }}</div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
</template>
<script>

export default {
  name: "ErrorBlockComponent",

  data() {
    return {
      error: '',
    }
  },
  methods: {
    async setError(text) {
      this.error = text;
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.error = '';
    },
  },
}
</script>

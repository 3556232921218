<template>
  <div>
    <h1>Mcws</h1>
    <ErrorBlockComponent ref="errorComponent" />

    <SelectorOrgCafeComponent
      :setError="setError"
      @onChangeOrgEv="org_id = $event"
      @onChangeOrgsEv="orgs = $event"
    />
    <McwsComponentTable
      :setError="setError"
      :org_id="org_id"
      :orgs="orgs"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorBlockComponent from '@/components/module-common-errorblock.vue'
import SelectorOrgCafeComponent from '@/components/module-smartmcw-selector_org_cafe.vue'
import McwsComponentTable from '@/components/module-smartmcw-showmcw_table.vue'

export default {
  name: "AdminMcws",

  components: {
    ErrorBlockComponent,
    SelectorOrgCafeComponent,
    McwsComponentTable,
  },

  data() {
    return {
      error: '',

      org_id: null,
      orgs: [],
    }
  },
  methods: {
    async setError(text) {
      this.$refs.errorComponent.setError(text);
    },
  },
}
</script>

<template>
  <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column vh-100">
    <header class="mb-auto">
      <div>
        <h3 class="float-md-start mb-0">{{ $site_name }}</h3>
        <nav class="nav nav-masthead justify-content-center float-md-end">
          <a class="nav-link fw-bold py-1 px-0 active" aria-current="page" href="#">{{ $t("message.promo_link") }}</a>
          <a v-if="!user" class="nav-link fw-bold py-1 px-0" href="/login">{{ $t("message.signin_link") }}</a>
          <a v-if="user" class="nav-link fw-bold py-1 px-0" href="/home">{{ $t("message.home_link") }}</a>
          <a v-if="user" class="nav-link fw-bold py-1 px-0" href="#" @click="signOut">{{ $t("message.signout_link") }}</a>
          <changeLang add_div_classes="ms-3" add_sel_classes="bg-dark text-white"/>

          <!-- <div class="ms-3">
            <select class="form-select form-select-sm bg-dark text-white" v-model="selected_locale" @change="changeLanguage">
              <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
            </select>
          </div> -->
        </nav>
      </div>


    </header>

    <main class="px-3">
      <h1>{{ $t("message.promo_action") }}</h1>
      <p>{{ $t("message.dev_notice") }}</p>
      <p class="lead">{{ $t("message.promo_text") }}</p>
      <p class="lead">
        <a v-if="user" href="/home" class="btn btn-lg btn-light fw-bold border-white bg-white">{{ $t("message.home_button") }}</a>
        <a v-if="!user" href="/signup" class="btn btn-lg btn-light fw-bold border-white bg-white">{{ $t("message.signup_button") }}</a>
      </p>
    </main>

    <footer class="mt-auto text-white-50">
      <p>{{ $site_copy }}</p>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import MyProfile from '@/components/module-common-my_profile.vue'

import { accountsService } from "@/components/ajax-common-account.js";
// import router from "@/router";
import changeLang from "@/components/module-common-change_lang.vue";

export default {
  name: "CommonMain",
  components: {
    changeLang
  },
  data() {
    return {
      error: '',
      user: '',
    }
  },

  methods: {
    signOut() {
      accountsService.logout()
        // .then(res => {
        .then(() => {
          this.getme();
        })
    },
    getme() {
      accountsService.getMe()
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.user = data;
        })
        .catch(
          error => {
            this.user = '';
            if (error.body) {
              this.error = error.body;
            } else {
              this.error = error.error;

              // if (this.error === "You are already logged") {
              // }
            }
            // this.loading = false;
          }
        );
    },
  },

  created() {
    this.getme();
    // this.signout();
    // this.signin();
  },
  mounted() {
    document.body.classList.add('text-center', 'text-bg-dark')
  },
  unmounted() {
    document.body.classList.remove('text-center', 'text-bg-dark')
  }

  // components: {
  //   MyProfile
  // }
}
</script>
<style>
/* cover */

/* Custom default button */
.btn-light,
.btn-light:hover,
.btn-light:focus {
  color: #333;
  text-shadow: none;
  /* Prevent inheritance from `body` */
}


.cover-container body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

.cover-container {
  max-width: 42em;
}


/*
 * Header
 */

.nav-masthead .nav-link {
  color: rgba(255, 255, 255, .5);
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link+.nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

/* settings */
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}
</style>
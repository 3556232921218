<script>
import router from "@/router";
import { gravatar } from "@/components/module-common-gravatar.js";
import { accountsService } from "@/components/ajax-common-account.js";

export default {
  name: 'MyProfile',
  data() {
    return {
      user: {
        perms: [],
        url_avatar: null,
        error: "",
      },
    };
  },
  methods: {
    getMyAccount() {
      accountsService.getMe()
        .then(data => {
          // if (this.$is_debug) console.log("Response message:", data);
          if (!data.email_is_verified) {
            router.push("/verify");
          }
          this.user = data;
          this.getImageUrl();
          this.$emit('userDataLoaded', this.user);
        })
        .catch(
          error => {
            if (error.body) {
              this.error = error.body;
            } else {
              this.error = error;
            }

            if (error.status && error.status === 502) {
              this.error = "Backend is not ready";
            }

            accountsService.checkIfLogin(this.error, true);
          }
        );
    },
    getImageUrl() {
      if (this.user.url_avatar === null) {
        this.user.url_avatar = gravatar.getDefaultImage(this.user.name, this.user.surname);
      }
    },
  },
  created() {
    this.getMyAccount();
  },
  mounted() {
  }
};
</script>

<template>
  <div class="modal fade" ref="modal" id="createModal" tabindex="-1" aria-labelledby="createModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="createCollection">

          <div class="modal-header">
            <h5 class="modal-title" id="createModalLabel">New Сollection</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              @click="cleanupCreateForm"></button>
          </div>
          <div class="modal-body">
            <div v-if="form_error" class="alert alert-danger mb-3">{{ form_error }}</div>

            <div class="form-floating">
              <input type="text" class="form-control" v-model="form_name" name="form_name" id="form_name"
                placeholder="Collection name"
                :class="{ 'is-invalid': form_submitted && (!form_name || !form_name_is_valid) }" />
              <label for="form_name" v-show="!form_submitted || form_name">Collection</label>
              <div v-show="form_submitted && !form_name" class="invalid-feedback">Name is required</div>
              <div v-show="form_submitted && !form_name_is_valid" class="invalid-feedback">Name must be at least 8
                characters long</div>
            </div>

            <div class="form-floating my-3">
              <textarea class="form-control" v-model="form_description" name="form_description" id="form_description"
                placeholder="Collection description" style="height: 200px;"></textarea>
              <label for="form_description">Description</label>
            </div>

            <div class="form-check text-start my-3">
              <input class="form-check-input" type="checkbox" v-model="form_is_public" name="form_is_public"
                value="form_is_public" id="form_is_public" data-bs-toggle="tooltip"
                title="Check this box if the collection should be public">
              <label class="form-check-label" for="form_is_public">
                Public collection
              </label>
            </div>

          </div>


          <div class="modal-footer">
            <button type="button" class="btn btn-secondary py-2" data-bs-dismiss="modal"
              @click="cleanupCreateForm">Close</button>
            <button type="submit" class="btn btn-primary py-2" v-bind:disabled="form_is_loading"><span v-if="form_is_loading"
                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Create</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { collectionsService } from "@/components/ajax-eav-collection.js";

export default {
  name: 'CreateColModal',
  data() {
    return {
      form_error: '',
      form_name: '',
      form_description: '',
      form_is_public: false,
      form_submitted: false,
      modal_is_open: true,
      form_is_loading: false,
    };
  },
  props: {
    method_to_refresh: {
      type: Function,
      required: true
    }
  },
  methods: {
    trimInput() {
      this.form_name = this.form_name.trim();
    },
    closeModal() {
      this.form_error = '';
      var myModalEl = document.getElementById('createModal')
      var modal = Modal.getInstance(myModalEl);
      modal.hide();
    },
    createCollection() {
      this.form_submitted = true;
      const { form_name, form_description, form_is_public } = this;

      // console.log(remember_me);
      // stop here if form is invalid
      if (!form_name || !this.form_name_is_valid) {
        return;
      }

      // console.log(email, password);

      this.form_is_loading = true;
      collectionsService.create(form_name, form_description, form_is_public)
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          // router.push("/home");
          this.cleanupCreateForm();
          this.closeModal();
          this.method_to_refresh();
          this.form_is_loading = false;
        })
        .catch(
          error => {
            if (error.body) {
              this.form_error = error.body;
            } else {
              this.form_error = error.error;
            }
            this.form_is_loading = false;
          }
        );
    },
    cleanupCreateForm() {
      this.form_error = '';
      this.form_name = '';
      this.form_description = '';
      this.form_is_public = false;

      this.form_submitted = false;
    },
    // deprecated() {
    //   /* global bootstrap: false */
    //   (() => {
    //       'use strict'
    //       const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    //       tooltipTriggerList.forEach(tooltipTriggerEl => {
    //           new bootstrap.Tooltip(tooltipTriggerEl)
    //       })
    //   })();
    // },
  },
  computed: {
    form_name_is_valid() {
      const trimmedName = this.form_name.trim();
      return trimmedName.length >= 8;
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<template>
    <CreateOrgModal :method_to_refresh="getAllItems" />
    <UpdateOrgModal :organizations="listItems" :method_to_refresh="getAllItems" :getSelItem="getSelItem" />

    <div class="btn-container">
        <button class="btn btn-primary py-2 mr-2" type="submit" v-bind:disabled="!btn_refresh_col_is_active"
            @click="getAllItems(true)">
            <span v-if="btn_refresh_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Refresh
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" data-bs-toggle="modal" data-bs-target="#createModal">
            <span v-if="btn_create_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Create
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" @click="selectAllItem"
            v-bind:disabled="!areItemsPresent || areAllSelected">
            <span v-if="btn_selall_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Select All
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" @click="unSelectAllItem"
            v-bind:disabled="!isAtLeastOneSelected">
            <span v-if="btn_unselall_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Unselect All
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" v-bind:disabled="!isOnlyOneSelected"
            data-bs-toggle="modal" data-bs-target="#updateModal">
            <span v-if="btn_edit_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Edit
        </button>

        <button class="btn btn-primary py-2" type="submit" @click="deleteSelectedItems"
            v-bind:disabled="!isAtLeastOneSelected || !btn_delete_col_is_active">
            <span v-if="btn_delete_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Delete
        </button>
    </div>

    <div v-if="listItems.length === 0" class="mt-3"><b>There are no available organizations (create first).</b></div>

    <div v-if="listItems.length !== 0" class="m-0 border-0 table-container">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Blocked</th>
                    <th scope="col">Created</th>
                </tr>
            </thead>
            <tbody>

                <tr v-for="s_item in listItems" :key="s_item.id" :class="{ 'table-active': isSelectedItem(s_item.id) }"
                    @click="toggleSelectionItem(s_item.id)">
                    <th scope="row">{{ s_item.name }}</th>
                    <td>{{ s_item.description }}</td>
                    <td>{{ s_item.is_blocked ? 'Blocked' : 'Active' }}</td>
                    <td>{{ s_item.created_at }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import CreateOrgModal from '@/components/modal-smartmcw-create_org.vue'
import UpdateOrgModal from '@/components/modal-smartmcw-update_org.vue'

import { orgsService } from "@/components/ajax-smartmcw-org.js";
import { accountsService } from "@/components/ajax-common-account.js";

export default {
    name: "AdminOrgsComponent",
    components: {
        CreateOrgModal,
        UpdateOrgModal,
    },

    data() {
        return {
            btn_refresh_col_loading: false,
            btn_refresh_col_is_active: true,
            btn_create_col_loading: false,
            btn_selall_col_loading: false,
            btn_unselall_col_loading: false,
            btn_edit_col_loading: false,
            btn_delete_col_is_active: true,
            btn_delete_col_loading: false,

            listItems: [],
            selectedItems: [],
            isOnlyOneSelected: false,
            isAtLeastOneSelected: false,
            areItemsPresent: false,
            areAllSelected: false,
        }
    },
    props: {
        setError: {
            type: Function,
            required: false,
        },
    },
    methods: {
        setModalError(text) {
            this.$refs.commonModal.setError(text);
        },
        proceedAction() {
            this.$refs.createOrg.createItem();
        },
        closeAction() {
            this.$refs.createOrg.cleanupCreateForm();
        },


        getAllItems(force = false) {
            this.btn_refresh_col_loading = true;
            orgsService.getAll(force)
                .then(data => {
                    if (this.$is_debug) console.log("Response message:", data);
                    this.listItems = data;

                    this.btn_refresh_col_loading = false;
                    this.countItems();
                })
                .catch(
                    async error => {
                        this.btn_refresh_col_loading = false;

                        if (error.body) {
                            this.setError(error.body);

                            accountsService.checkIfLogin(error.body, true);

                            if (error.body === "Too Many Requests") {
                                this.btn_refresh_col_is_active = false;
                                await new Promise(resolve => setTimeout(resolve, 5000));
                                this.btn_refresh_col_is_active = true;
                            }
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },

        getSelItem() {
            if (!this.isOnlyOneSelected) {
                this.setError("Selected more than one option!");
                return;
            }
            const itemId = this.selectedItems[0];
            const itemObj = this.listItems.find(item => item.id === itemId);
            return itemObj;
        },
        toggleSelectionItem(itemId) {
            if (this.isSelectedItem(itemId)) {
                // Удалить ID коллекции из массива, если она уже выбрана
                const index = this.selectedItems.indexOf(itemId);
                this.selectedItems.splice(index, 1);
            } else {
                // if (!this.en_multiple_choice) this.selectedItems = [];
                this.selectedItems.push(itemId);
            }
            this.countItems();
        },
        isSelectedItem(itemId) {
            // Проверить, выбрана ли коллекция
            return this.selectedItems.includes(itemId);
        },
        selectAllItem() {
            // Добавить все ID коллекций в массив выбранных
            this.selectedItems = this.listItems.map(item => item.id);
            this.countItems();
        },
        unSelectAllItem() {
            // Очистить массив выбранных коллекций
            this.selectedItems = [];
            this.countItems();
        },
        countItems() {
            this.isOnlyOneSelected = this.selectedItems.length === 1;
            this.isAtLeastOneSelected = this.selectedItems.length > 0;
            this.areItemsPresent = this.listItems.length > 0;
            this.areAllSelected = this.selectedItems.length === this.listItems.length;
        },
        async deleteSelectedItems() {
            const confirmation = window.confirm("Are you sure that you wanna delete selected items?");

            if (confirmation) {
                await this.doSequentially(this.selectedItems, this.deleteItem);
            }

            this.getAllItems(true);
        },
        async doSequentially(list, func) {
            if (list.length === 0) return;
            this.btn_delete_col_loading = true;
            const currentItem = list.shift();

            try {
                await func(currentItem);
                await this.doSequentially(list, func);
            } catch (error) {
                list.push(currentItem);
                this.setError("Error in doSequentially(): " + error);
            }
        },
        deleteItemFrInterface(id) {
            const index1 = this.listItems.findIndex(item => item.id === id);
            if (index1 !== -1) {
                this.listItems.splice(index1, 1);
            }

            // delete selected item from list
            // const index2 = this.selectedItems.indexOf(id);
            // if (index2 !== -1) {
            //   this.selectedItems.splice(index2, 1);
            // }

            this.countItems();
        },
        async deleteItem(id) {
            await orgsService.remove(id)
                .then(data => {
                    if (data === null) {
                        this.deleteItemFrInterface(id);
                    }
                    this.btn_delete_col_loading = false;
                    return true;
                })
                .catch(
                    error => {
                        this.btn_delete_col_loading = false;
                        if (error.body) {
                            const responseBody = error.body;
                            this.setError("Error from server: " + responseBody);

                            if (responseBody === "Too Many Requests") {
                                this.btn_delete_col_is_active = false;
                                new Promise(resolve => setTimeout(resolve, 5000));
                                this.btn_delete_col_is_active = true;
                            }
                        } else {
                            this.setError("Error: " + error.error);
                        }
                        return false;
                    }
                );
        },
    },
    mounted() {
        this.getAllItems();
    },
}
</script>
<style>
.table-container {
    max-width: 100%;
    /* Максимальная ширина контейнера */
    overflow-x: auto;
    /* Появление горизонтальной прокрутки при необходимости */
}

.table {
    width: 100%;
    /* Ширина таблицы равна 100% ширины контейнера */
    /* white-space: nowrap; Текст внутри ячеек не переносится */
}

.table th,
.table td {
    /* Сокрытие текста, который не помещается в ячейку */
    overflow: hidden;
    /* Отображение многоточия, если текст не помещается в ячейку */
    text-overflow: ellipsis;
    /* Перенос текста на следующую строку, если необходимо */
    word-wrap: break-word;
}

.btn-container {
    display: flex;
    flex-wrap: wrap;
}

.btn-container>.btn {
    margin-bottom: 0.5rem;
}
</style>
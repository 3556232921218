<template>

<!--

  * https://github.com/fengyuanchen/cropperjs/blob/main/README.md#getting-started
  * https://github.com/fengyuanchen/cropperjs/blob/main/docs/examples/upload-cropped-image-to-server.html
  * https://fengyuanchen.github.io/cropperjs/examples/upload-cropped-image-to-server.html
  * https://www.google.com/search?client=safari&rls=en&q=cropperjs+vue3+example&ie=UTF-8&oe=UTF-8
  * https://codepen.io/xyxiao001/pen/yLooYKg
  * https://gist.github.com/jay4497/0e80dd6f5ea1432cd8f1b4c232c95dce
  * https://www.youtube.com/watch?v=ic7jLBpU-8E
  * https://vuejs.org/guide/essentials/template-refs.html
  * https://github.com/JacobSNGoodwin/memrizr

-->

  <div class="form-group">
    <label class="label avatar-placeholder">
      <span class="upload-text">Click to update the image</span>
      <img id="form_cover" class="rounded" :src="current_image || defaultImage" alt="avatar">
      <input type="file" class="input-form" id="input" name="image" accept="image/*" @change="handleFileChange">
    </label>
  </div>

  <!-- <div class="progress">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0"
      aria-valuemin="0" aria-valuemax="100">0%</div>
  </div> -->

  <!-- <div class="alert" role="alert"></div> -->

  <div v-if="is_cropper_modal_open" class="modal fade show" style="display: block;" id="modalCropper" tabindex="0" role="dialog" aria-modal="true" aria-labelledby="modalCropperLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalCropperLabel">Crop the image</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
        </div>

        <div class="modal-body">
          <div class="img-container">
            <img ref="image" :src="imageCropper">
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary py-2" @click="closeModal">Close</button>
          <button type="button" class="btn btn-primary py-2" @click="cropImage">Crop</button>
        </div>
      </div>
    </div>
  </div>

</template>

<!--

  * https://github.com/fengyuanchen/cropperjs/blob/main/docs/examples/upload-cropped-image-to-server.html
  * https://fengyuanchen.github.io/cropperjs/examples/upload-cropped-image-to-server.html
  * https://www.google.com/search?client=safari&rls=en&q=cropperjs+vue3+example&ie=UTF-8&oe=UTF-8
  * https://codepen.io/xyxiao001/pen/yLooYKg
  * https://gist.github.com/jay4497/0e80dd6f5ea1432cd8f1b4c232c95dce
  * https://www.youtube.com/watch?v=ic7jLBpU-8E
  * https://vuejs.org/guide/essentials/template-refs.html
  * https://github.com/JacobSNGoodwin/memrizr

-->


<script>
import 'cropperjs/dist/cropper.min.css';
import Cropper from 'cropperjs';

export default {
  name: 'CropperModal',
  data() {
    return {
      // defaultImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACm0lEQVR4nO2Zv2sUQRTHP+ZwRY8kYlAQNBHFSsRGEFRSeFECgiCilWJloQn+B6Jd9FKITUwQVLDRQsTCwsJKwUJQMQZRMPGSRgQxkfN39GThDQyPu8vu7O2Pg/3CcMfe+/V9s/PmzRzkyJEjR9axCZgA5oBFoBbzWBRf40Bf1OAPAF8TCLrWYCwAA1Eyn2bwNYtErwuBCcvINLAP8IgfHlACZiz/V10MzVkG/OCTRsnyX3ExYC/YJDKv4amFHRr2e5gWalFiiIPAaqAs7/cv+bwEdLcDgS5gskGleQV0Zp3A6BLlspx1Aq8te2eAVcCQ9Wwy6wSqlj0/eB9F61m1nWZgSEgMt9MMlJdYAxezTqBTqk0mqlBRqsobabaeAEcC2OyWTL8HfsrniJRYkiKwBnjWIJMXXBw1QcsJrANeOtTzTBBYD0xZz/4Cp4Ee4IGSvwIsyxKBjcA71R0eV53jPaXjHwk7lE1/Bq/J+eIL8BDoT4LAtPX9N3C0jvxy4LbSuwkU5PfN6pBixh/gZNwEzPA7yMNNdPxgryudO8A2dUDS459sarES+A4MBtDrUMdRk2XbziFgA/BckTgbF4FvIW8H/AV8uU4SqnJUtM8HT5XMuTgI7HHQ3yWvnLExD+yuI9cFPFb+zreaQFjslR3a6H8GdjaRLwKPlM+RtAj0q/ukT8COAHorgPtNFnoiBAZlkRq9j8D2EPoecDctAgeBH5bOLLDVwW8BuJU0gWOywRn5D8AW3FEAbkQlEPRi64SSfSutR1SsjHqxNRvgavGUNHVGbkqavlZgQM1oaIxbBmbEoD0Tw7J7GpkXwNoWBO4B+1XfNOZiqE/V8rTGvOv1OpL1hZSDLxERvTKFlQT/YqqIT+fM58iRIweJ4D9W10XJ9y0YggAAAABJRU5ErkJggg==',
      defaultImage: "/default_menu.jpg",
      imageCropper: '',
      progress: false,
      progressBarValue: 0,
      alertVisible: false,
      cropper: null,

      is_cropper_modal_open: false,
    };
  },
  props: {
    setUpdatedImage: {
      type: Function,
      required: true
    },
    current_image: {
      type: String,
      required: true
    },
  },
  computed: {},
  methods: {
    destroyCropper() {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
    },
    async openModal() {
      this.is_cropper_modal_open = true;

      // const modal = document.getElementById('modalCropper');
      // modal.classList.add('show');
      // modal.style.display = 'block';
      // modal.setAttribute('aria-modal', 'true');

      await new Promise(resolve => setTimeout(resolve, 50));
      this.initializeCropper();

      // this.$refs.image.onload = () => {
      //   console.log("image.onload");
      //   this.initializeCropper();
      // };
    },
    closeModal() {
      this.is_cropper_modal_open = false;

      // const modal = document.getElementById('modalCropper');
      // modal.classList.remove('show');
      // modal.style.display = 'none';
      // modal.removeAttribute('aria-modal');

      this.destroyCropper();
    },
    handleFileChange(event) {
      const files = event.target.files;

      const done = (url) => {
        event.target.value = ''; // Очищаем поле выбора файла
        this.imageCropper = url,
        this.alertVisible = false;

        this.openModal();
      };

      let reader;
      let file;

      if (files && files.length > 0) {
        file = files[0];

        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = function (e) {
            done(e.target.result);
          };
          reader.readAsDataURL(file);
        }
      }
    },
    initializeCropper() {
      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        viewMode: 2,
        autoCropArea: 1,
        // dragMode: 'move',
        // zoomable: false, // Запретить масштабирование
      });

    },
    cropImage() {
      if (this.cropper) {
        const canvas = this.cropper.getCroppedCanvas({ width: 400, height: 400 });
        if (canvas) {

          // const dpi = 144;
          // canvas.width = 200 * dpi / 72;
          // canvas.height = 200 * dpi / 72;

          // // Получить контекст для рисования на холсте
          // const context = canvas.getContext('2d');

          // // Установить DPI для контекста
          // context.scale(dpi / 72, dpi / 72);

          // // Нарисовать обрезанное изображение на холсте с учетом установленного DPI
          // context.drawImage(this.$refs.image, 0, 0, canvas.width, canvas.height);

          const croppedImageSrc = canvas.toDataURL('image/jpeg', 0.9);

          // canvas.toBlob((blob) => {
          //     this.croppedImageSrc = URL.createObjectURL(blob);
          // }, 'image/jpeg', 1);

          this.setUpdatedImage(croppedImageSrc);
        } else {
          console.error('Failed to crop image: Canvas is null');
        }
      }


          // const form = new FormData;
          // form.append('file', blob, 'cropped-image.jpg');
          // 提交 form 表单


      this.closeModal();
    },
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style scoped>
.input-form {
  display: none;
}

.label {
  cursor: pointer;
}

.progress {
  display: none;
  margin-bottom: 1rem;
}

.alert {
  display: none;
}

.img-container img {
  display: block;
  width: 100%;
}

#form_cover {
  width: 200px;
  height: 200px;
}

.avatar-placeholder {
  background-color: #ccc;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5%;
}

.avatar-placeholder.active::before {
  content: '\1F4F7';
  /* Unicode-символ для камеры */
  font-size: 40px;
}

.upload-text {
  position: absolute;
  top: 80px;
  left: 300px;
  transform: translateX(-50%);
  font-size: 14px;
  color: #8d8888;
  background-color: rgba(255, 255, 255, 0.7);
  /* Цвет фона с небольшой прозрачностью */
  padding: 4px 8px;
  border-radius: 4px;
  /* pointer-events: none; Чтобы текст не перехватывал события мыши */
  width: 200px;
}
</style>

<template>
  <main class="form-signin w-100 m-auto">

    <div class="d-flex justify-content-between mb-3">
      <a href="/">
        <img class="mb-3" :src="$site_logo" :alt="$site_name" height="57" style="margin-left:-5px;">
      </a>
      <changeLang add_sel_classes="w-auto mr-auto"/>
    </div>

    <div class="d-flex justify-content-between mb-3 align-items-end">
      <h1>{{ $t("notfound.message_404") }}</h1>
    </div>

    <div>
      <a href="#" @click="goCover">{{ $t("notfound.home_link") }}</a>
    </div>

    <p class="mt-5 mb-3 text-body-secondary">{{ $site_copy }}</p>
  </main>
</template>

<script>
// import router from "@/router";
import changeLang from "@/components/module-common-change_lang.vue";

export default {
  name: 'NotFound',
  components: {
    changeLang
  },
  data() {
    return {
    };
  },
  methods: {
    goCover() {
      this.$router.push('/');
    },
  },
  created() {
  },
  mounted() {
    document.body.classList.add('d-flex', 'justify-content-center', 'align-items-center')
  },
  unmounted() {
    document.body.classList.remove('d-flex', 'justify-content-center', 'align-items-center')
  }
};
</script>
<style>
/* signin */
html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}
</style>
<template>
  <div>
    <h1>Access</h1>
    <ErrorBlockComponent ref="errorComponent" />

    <SelectorObjectComponent
      :setError="setError"
      @onChangeObjEv="obj_id = $event"
    />
    <AccessComponent
      :setError="setError"
      :obj_id="obj_id"
    />
  </div>
</template>

<script>
import ErrorBlockComponent from '@/components/module-common-errorblock.vue';
import SelectorObjectComponent from '@/components/module-smartmcw-selector_object.vue';
import AccessComponent from '@/components/module-smartmcw-show_access.vue';

export default {
  name: "AdminAccess",

  components: {
    ErrorBlockComponent,
    SelectorObjectComponent,
    AccessComponent,
  },

  data() {
    return {
      obj_id: "",
    }
  },

  watch: {
  },
  methods: {
    async setError(text) {
      this.$refs.errorComponent.setError(text);
    },
  },
}
</script>

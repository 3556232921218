<template>
    <select class="form-select" id="itemSelect" v-model="selected_index" @change="addSelectedItem"
        :disabled="available_items.length === 0">
        <option value="" disabled selected hidden>{{ available_items.length === 0 ? 'There are no available ' + label + "s" :
        'Choose ' + label + "s" }}</option>
        <option v-for="item in available_items" :key="item.id" :value="item.id">{{ item.name }}</option>
    </select>
    <label for="itemSelect">{{ firstLetterToUppercase(label + "s")}}:</label>
    <div class="tags my-3">
        <span v-for="item in selected_items" :key="item.id" class="badge bg-primary" @click="delItem(item.id)"
            style="cursor: pointer;">{{ item.name }} <span>&times;</span></span>
    </div>
</template>
<script>
export default {
    name: 'SelectSumComponent',
    components: {
    },
    data() {
        return {
            selected_index: "",
            selected_items: [],
            available_items: [],
        };
    },
    props: {
        label: {
            type: String,
            required: false,
            default: function () {
                return "item";
            },
        },
    },
    methods: {
        delData() {
            this.selected_items = [];
            this.available_items = [];
        },
        setData(all_items=[], sel_items=[]) {
            this.available_items = all_items.map(item => ({ id: item.id, name: item.name }));
            sel_items.forEach(item => { this.addItem(item.id) });
        },
        firstLetterToUppercase(word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        },
        addSelectedItem() {
            if (this.selected_index) {
                this.addItem(this.selected_index);
                this.selected_index = "";
            }
        },
        addItem(itemId) {
            const item = this.available_items.find(item => item.id === itemId);
            this.selected_items.push(item);
            this.available_items = this.available_items.filter(item => item.id !== itemId);
            this.$emit("onChange", this.selected_items);
        },
        delItem(itemId) {
            const item = this.selected_items.find(item => item.id === itemId);
            this.available_items.push(item);
            this.available_items.sort((a, b) => a.name.localeCompare(b.name));
            this.selected_items = this.selected_items.filter(item => item.id !== itemId);
            this.$emit("onChange", this.selected_items);
        },
    },
}
</script>
<style scoped>
.tags {
    display: flex;
    flex-wrap: wrap;
}

.tags .badge {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
</style>
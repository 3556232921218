import axios from 'axios';

import { ssService } from './ajax-common-ss';

export const mcwsService = {
  getAll,
  create,
  update,
  remove,
  getState,
  startMcw,
  stopMcw,
};

async function getAll(forced = false, org_id = null, cafe_id = null, skip = 0, limit = 100) {
  return ssService.getCached("mcws", 5, forced, getAllOld, org_id, cafe_id, skip, limit);
}

function getAllOld(org_id = null, cafe_id = null, skip = 0, limit = 100) {
   return axios.get('/api/v1/mcw/', {
    params: {
      org_id: org_id,
      cafe_id: cafe_id,
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

async function getState(id, forced = false) {
  return ssService.getCached("mcw_state", 3, forced, getStateOld, id);
}

function getStateOld(id) {
  return axios.get(
    '/api/v1/mcw/state/' + id,
    {
      withCredentials: true,
      timeout: 500,
    }
  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

function create(
  name,
  description,
  is_blocked,
  version,
  secret_key,
  cafe_id,
  org_id,
) {
  return axios.post('/api/v1/mcw/', {
    withCredentials: true,

    name,
    description,
    is_blocked,
    version,
    secret_key,
    cafe_id,
    org_id,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

function update(
  id,
  name = null,
  description = null,
  version = null,
  secret_key = null,
  org_id = null,
  cafe_id = null,
) {
  return axios.patch('/api/v1/mcw/' + id, {
    withCredentials: true,

    id: id,
    name: name,
    description: description,
    version: version,
    secret_key: secret_key,
    org_id: org_id,
    cafe_id: cafe_id,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

function remove(id) {
  return axios.delete('/api/v1/mcw/' + id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function startMcw(id, menu_id) {
  return axios.post('/api/v1/mcw/start/' + id, {
    withCredentials: true,

    menu_id: menu_id,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function stopMcw(id) {
  return axios.post('/api/v1/mcw/stop/' + id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}

<template>
    <h3>Roles</h3>

    <div class="btn-container">
        <button class="btn btn-primary py-2 mr-2" type="submit" v-bind:disabled="!btn_refresh_col_is_active"
            @click="getAllItems(true)">
            <span v-if="btn_refresh_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Refresh
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" @click="listObj.selectAll"
            v-bind:disabled="!listObj.areItemsPresent() || listObj.areAllSelected()">
            <span v-if="btn_selall_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Select All
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" @click="listObj.unSelectAll"
            v-bind:disabled="!listObj.isAtLeastOneSelected()">
            <span v-if="btn_unselall_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Unselect All
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" @click="acceptSelectedItems"
            v-bind:disabled="!listObj.isAtLeastOneSelected() || !btn_delete_col_is_active">
            <span v-if="btn_delete_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Accept
        </button>

        <button class="btn btn-primary py-2" type="submit" @click="declineSelectedItems"
            v-bind:disabled="!listObj.isAtLeastOneSelected() || !btn_delete_col_is_active">
            <span v-if="btn_delete_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Decline
        </button>
    </div>

    <div v-if="listObj.isListEmpty()" class="mt-3 mb-3"><b>There are no available access (ask to provide first).</b>
    </div>
    <div v-if="!listObj.isListEmpty()" class="m-0 border-0 table-container">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Object</th>
                    <th scope="col">Name</th>
                    <th scope="col">Confirmed</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in listObj.all_items" :key="item.id" @click="listObj.toggleItem(item.id)"
                    :class="{ 'table-active': listObj.isSelected(item.id) }">
                    <td>
                        <div>{{ item.object.type }}: {{ item.object.name }} ({{ item.object.id }})</div>
                        <div v-if="item.object.org && item.object.type === 'Cafe'">{{ item.object.org.type }}: {{ item.object.org.name }} ({{ item.object.org.id }})</div>
                    </td>
                    <th scope="row">{{ item.name }} ({{ item.id }})</th>
                    <td>
                        <span v-if="item.is_confirmed" class="badge text-bg-success">Confirmed</span>
                        <span v-if="!item.is_confirmed" class="badge text-bg-warning">Unconfirmed</span>
                    </td>
                    <td>
                        <button v-if="item.is_confirmed" class="btn btn-sm btn-primary" @click="decline(item.id)">Reject</button>
                        <button v-if="!item.is_confirmed" class="btn btn-sm btn-primary" @click="accept(item.id)">Accept</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</template>
<script>
import { accountsService } from "@/components/ajax-common-account.js";
import { rolesService } from "@/components/ajax-common-roles.js";
import { ListComponent } from "@/components/module-common-features.js";

export default {
    name: "PersonalRolesComponent",

    data() {
        return {
            btn_refresh_col_loading: false,
            btn_refresh_col_is_active: true,
            btn_create_col_loading: false,
            btn_selall_col_loading: false,
            btn_unselall_col_loading: false,
            btn_edit_col_loading: false,
            btn_delete_col_is_active: true,
            btn_delete_col_loading: false,

            listObj: new ListComponent(this.setError),
        }
    },
    props: {
        setError: {
            type: Function,
            required: false,
        },
    },
    mounted() {
        this.getAllItems();
    },
    methods: {
        async accept(role_id, refresh = true) {
            await rolesService.accept(role_id)
                .then(data => {
                    if (this.$is_debug) console.log("Response message:", data);
                    if (refresh) this.getAllItems(true); // refresh data
                })
                .catch(
                    async error => {

                        if (error.body) {
                            this.setError(error.body);

                            accountsService.checkIfLogin(error.body, true);

                            if (error.body === "Too Many Requests") {
                                await new Promise(resolve => setTimeout(resolve, 5000));
                            }
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },
        async decline(role_id, refresh = true) {
            await rolesService.decline(role_id)
                .then(data => {
                    if (this.$is_debug) console.log("Response message:", data);
                    if (refresh) this.getAllItems(true); // refresh data
                })
                .catch(
                    async error => {

                        if (error.body) {
                            this.setError(error.body);

                            accountsService.checkIfLogin(error.body, true);

                            if (error.body === "Too Many Requests") {
                                await new Promise(resolve => setTimeout(resolve, 5000));
                            }
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },
        async getAllItems(forced = false) {
            await rolesService.getUserRoles(forced)
                .then(data => {
                    if (this.$is_debug) console.log("Response message:", data);

                    this.listObj.all_items = data;
                    this.listObj.sortItems(); // sort by name
                })
                .catch(
                    async error => {

                        if (error.body) {
                            this.setError(error.body);

                            accountsService.checkIfLogin(error.body, true);

                            if (error.body === "Too Many Requests") {
                                await new Promise(resolve => setTimeout(resolve, 5000));
                            }
                        } else {
                            this.setError(error.error);
                        }
                    }
                );
        },
        async acceptSelectedItems() {
            const confirmation = window.confirm("Are you sure that you wanna accept selected items?");
            if (confirmation) {
                await this.listObj.doSequentially(this.listObj.sel_items, async (item) => { await this.accept(item.id, false); });
                await this.getAllItems(true);
            }
        },

        async declineSelectedItems() {
            const confirmation = window.confirm("Are you sure that you wanna decline selected items?");
            if (confirmation) {
                await this.listObj.doSequentially(this.listObj.sel_items, async (item) => { await this.decline(item.id, false); });
                await this.getAllItems(true);
            }
        },
    },
}
</script>
<style>
.table-container {
    max-width: 100%;
    /* Максимальная ширина контейнера */
    overflow-x: auto;
    /* Появление горизонтальной прокрутки при необходимости */
}

.table {
    width: 100%;
    /* Ширина таблицы равна 100% ширины контейнера */
    /* white-space: nowrap; Текст внутри ячеек не переносится */
}

.table th,
.table td {
    /* Сокрытие текста, который не помещается в ячейку */
    overflow: hidden;
    /* Отображение многоточия, если текст не помещается в ячейку */
    text-overflow: ellipsis;
    /* Перенос текста на следующую строку, если необходимо */
    word-wrap: break-word;
}

.btn-container {
    display: flex;
    flex-wrap: wrap;
}

.btn-container>.btn {
    margin-bottom: 0.5rem;
}
</style>
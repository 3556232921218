<template>
  <div>
    <h1>Cafes</h1>
    <ErrorBlockComponent ref="errorComponent" />

    <SelectorOrgCafeComponent :setError="setError" @onChangeOrgEv="org_id = $event" @onChangeOrgsEv="orgs = $event" />
    <CafeComponent :setError="setError" :formdata_org_id="org_id" :orgs="orgs" />
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorBlockComponent from '@/components/module-common-errorblock.vue'
import SelectorOrgCafeComponent from '@/components/module-smartmcw-selector_org_cafe.vue'
import CafeComponent from '@/components/module-smartmcw-showcafe.vue'

export default {
  name: "AdminCafes",

  components: {
    ErrorBlockComponent,
    SelectorOrgCafeComponent,
    CafeComponent,
  },

  data() {
    return {
      org_id: '',
      orgs: [],
    }
  },
  methods: {
    async setError(text) {
      this.$refs.errorComponent.setError(text);
    },
  },
}
</script>

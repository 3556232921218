<template>
    <CommonModal ref="createModal" modal_id="createModal" header="Provide Access" btn_text="Provide"
        @onOpen="refMethod('createForm', 'renderForm')" @onSubmit="refMethod('createForm', 'updateItem')"
        @onClose="refMethod('createForm', 'cleanupUpdateForm')">

        <UpdateAccessForm ref="createForm" :setError="refMethod.bind(this, 'createModal', 'setError')"
            :closeModal="refMethod.bind(this, 'createModal', 'closeAction')"
            @onLoading="refMethod('createModal', 'setIsLoading', $event)" :method_to_refresh="getAllItems"
            :obj_id="obj_id" :roles="listRoles" :groups="listGroups" :getSelItem="getSelItem" />
    </CommonModal>

    <CommonModal ref="updateModal" modal_id="updateModal" header="Update Access" btn_text="Update"
        @onOpen="refMethod('updateForm', 'renderForm')" @onSubmit="refMethod('updateForm', 'updateItem')"
        @onClose="refMethod('updateForm', 'cleanupUpdateForm')">

        <UpdateAccessForm ref="updateForm" :setError="refMethod.bind(this, 'updateModal', 'setError')"
            :closeModal="refMethod.bind(this, 'updateModal', 'closeAction')"
            @onLoading="refMethod('updateModal', 'setIsLoading', $event)" :method_to_refresh="getAllItems"
            :obj_id="obj_id" :roles="listRoles" :groups="listGroups" :is_updating="true" :getSelItem="getSelItem" />
    </CommonModal>

    <h3>Object User Associations</h3>

    <div class="alert alert-warning" role="alert">
      <div>
        Будьте осторожны с редактированием прав доступа у собственного аккаунта, тк вы сами можете остаться без прав к своим огранизациям / кафе.
      </div>
      <div>
        После добавления пользователю роли или группы - ему нужно будет подтвердить это у себя в профиле (ссылка доступна по нажатии на иконку в левом нижнем углу).
      </div>
    </div>

    <div class="btn-container">
        <button class="btn btn-primary py-2 mr-2" type="submit" v-bind:disabled="!btn_refresh_col_is_active"
            @click="getAllItems(true)">
            <span v-if="btn_refresh_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Refresh
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" data-bs-toggle="modal" data-bs-target="#createModal">
            <span v-if="btn_create_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Create
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" @click="selectAllItem"
            v-bind:disabled="!areItemsPresent || areAllSelected">
            <span v-if="btn_selall_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Select All
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" @click="unSelectAllItem"
            v-bind:disabled="!isAtLeastOneSelected">
            <span v-if="btn_unselall_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Unselect All
        </button>

        <button class="btn btn-primary py-2 mr-2" type="submit" v-bind:disabled="!isOnlyOneSelected"
            data-bs-toggle="modal" data-bs-target="#updateModal">
            <span v-if="btn_edit_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Edit
        </button>

        <button class="btn btn-primary py-2" type="submit" @click="deleteSelectedItems"
            v-bind:disabled="!isAtLeastOneSelected || !btn_delete_col_is_active">
            <span v-if="btn_delete_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Delete
        </button>
    </div>

    <div v-if="listUsers.length === 0" class="mt-3 mb-3"><b>There are no available organizations (create first).</b>
    </div>
    <div v-if="listUsers.length !== 0" class="m-0 border-0 table-container">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">User</th>
                    <th scope="col">Roles</th>
                    <th scope="col">Groups</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="s_item in listUsers" :key="s_item.user" @click="toggleSelectionItem(s_item.user)"
                    :class="{ 'table-active': isSelectedItem(s_item.user) }">
                    <th scope="row">{{ s_item.user }}</th>
                    <td v-html="formatDirectRolesTable(s_item.direct_roles)"></td>
                    <td v-html="formatRolesGroupsTable(s_item.groups)"></td>
                </tr>
            </tbody>
        </table>
    </div>

    <hr />

    <h3>Object Groups</h3>
    <div v-if="Object.keys(listGroups).length === 0" class="mt-3 mb-3"><b>There are no available groups for the
            object.</b></div>
    <div v-if="Object.keys(listGroups).length !== 0" class="m-0 border-0 table-container">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Roles</th>
                </tr>
            </thead>
            <tbody>

                <tr v-for="s_item in listGroups" :key="s_item.id">
                    <td>{{ s_item.id }}</td>
                    <th scope="row">{{ s_item.name }}</th>
                    <td v-html="formatRolesTable(s_item.roles)"></td>
                </tr>
            </tbody>
        </table>
    </div>

    <hr />

    <h3>Object Roles</h3>
    <div v-if="listRoles.length === 0" class="mt-3"><b>There are no available roles for the object.</b></div>
    <div v-if="listRoles.length !== 0" class="m-0 border-0 table-container">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">ID</th>
                </tr>
            </thead>
            <tbody>

                <tr v-for="s_item in listRoles" :key="s_item.id">
                    <th scope="row">{{ s_item.name }}</th>
                    <td>{{ s_item.id }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import CommonModal from "@/components/modal-smartmcw-common.vue";
import UpdateAccessForm from "@/components/form-smartmcw-update_access.vue";
import { accountsService } from "@/components/ajax-common-account.js";
import { groupsService } from "@/components/ajax-common-groups.js";
import { rolesService } from "@/components/ajax-common-roles.js";

export default {
    name: "AccessComponent",
    components: {
        CommonModal,
        UpdateAccessForm,
    },
    data() {
        return {
            btn_refresh_col_loading: false,
            btn_refresh_col_is_active: true,
            btn_create_col_loading: false,
            btn_selall_col_loading: false,
            btn_unselall_col_loading: false,
            btn_edit_col_loading: false,
            btn_delete_col_is_active: true,
            btn_delete_col_loading: false,

            listRoles: [],
            listGroups: {},
            listUsers: [],
            selectedItems: [],
            isOnlyOneSelected: false,
            isAtLeastOneSelected: false,
            areItemsPresent: false,
            areAllSelected: false,
        }
    },
    props: {
        setError: {
            type: Function,
            required: false,
        },
        obj_id: {
            type: String,
            required: true,
        },
    },
    watch: {
        obj_id() {
            this.getAllItems();
        },
    },
    computed: {
        isListEmpty() {
            return this.listUsers.length === 0;
        }
    },
    methods: {
        refMethod(refName, methodName, ...args) {
            // instead of using labda functions:
            // () => { if ($refs.createForm) $refs.createForm.cleanupCreateForm() }
            if (this.$refs[refName] && typeof this.$refs[refName][methodName] === 'function') {
                this.$refs[refName][methodName](...args);
            }
        },
        transformData(data) {
            const userMap = new Map();

            data.forEach(role => {
                role.user_associations.forEach(user => {
                    if (!userMap.has(user.user.email)) {
                        userMap.set(user.user.email, {
                            user: user.user.email,
                            direct_roles: [],
                            groups: []
                        });
                    }
                    userMap.get(user.user.email).direct_roles.push({
                        id: role.id,
                        name: role.name,
                        is_confirmed: user.is_confirmed
                    });
                });

                role.groups.forEach(group => {
                    group.user_associations.forEach(user => {
                        if (!userMap.has(user.user.email)) {
                            userMap.set(user.user.email, {
                                user: user.user.email,
                                direct_roles: [],
                                groups: []
                            });
                        }
                        const userGroups = userMap.get(user.user.email).groups;
                        let existingGroup = userGroups.find(g => g.id === group.id);
                        if (!existingGroup) {
                            existingGroup = {
                                id: group.id,
                                name: group.name,
                                is_confirmed: user.is_confirmed,
                                roles: []
                            };
                            userGroups.push(existingGroup);
                        }
                        existingGroup.roles.push({
                            id: role.id,
                            name: role.name
                        });
                    });
                });
            });

            // Сортируем роли внутри direct_roles и groups
            userMap.forEach(userData => {
                userData.direct_roles.sort((a, b) => a.name.localeCompare(b.name));
                userData.groups.forEach(group => {
                    group.roles.sort((a, b) => a.name.localeCompare(b.name));
                });
            });

            return Array.from(userMap.values());
        },
        formatDirectRolesTable(direct_roles) {
            let table = '<pre style="margin: 0;">';
            direct_roles.forEach(role => {
                table += `\n`;
                table += `ID: ${role.id}\n`;
                table += `Name: ${role.name}\n`;
                table += `Is Confirmed: ${role.is_confirmed}\n`;
            });
            table += '</pre>';
            return table;
        },
        formatRolesTable(roles) {
            let table = '<pre style="margin: 0;">';
            roles.forEach(role => {
                table += `ID: ${role.id}, Name: ${role.name}\n`;
            });
            table += '</pre>';
            return table;
        },
        formatRolesGroupsTable(groups) {
            let table = '<pre style="margin: 0;">';
            groups.forEach(group => {
                table += `\n`;
                table += `ID: ${group.id}\n`;
                table += `Name: ${group.name}\n`;
                table += `Is Confirmed: ${group.is_confirmed}\n`;
                // table += `Roles:\n`;
                // group.roles.forEach(role => {
                //     table += `  - ID: ${role.id}, Name: ${role.name}\n`;
                // });
                // table += '\n';
            });
            table += '</pre>';
            return table;
        },
        async getAllItems(forced = false) {
            if (this.obj_id) {
                this.cleanList();

                await rolesService.getAll(forced, this.obj_id)
                    .then(data => {
                        if (this.$is_debug) console.log("Response message:", data);

                        this.listRoles = data.sort((a, b) => a.name.localeCompare(b.name));
                        this.listUsers = this.transformData(data);
                        this.listGroups = this.extractGroupsWithRoles(data);
                        // this.listUsers.sort((a, b) => {
                        //     return new Date(a.created_at) - new Date(b.created_at);
                        // });

                        this.countItems();
                    })
                    .catch(
                        async error => {

                            if (error.body) {
                                this.setError(error.body);

                                accountsService.checkIfLogin(error.body, true);

                                if (error.body === "Too Many Requests") {
                                    await new Promise(resolve => setTimeout(resolve, 5000));
                                }
                            } else {
                                this.setError(error.error);
                            }
                        }
                    );
            }
        },
        extractGroupsWithRoles(data) {
            const groupMap = {};

            data.forEach(role => {
                role.groups.forEach(group => {
                    if (!groupMap[group.id]) {
                        groupMap[group.id] = {
                            id: group.id,
                            name: group.name,
                            roles: []
                        };
                    }

                    const roleInfo = {
                        id: role.id,
                        name: role.name
                    };
                    groupMap[group.id].roles.push(roleInfo);
                });
            });

            return Object.values(groupMap);
        },

        prepareLists(currentList, futureList) {
            const currentIds = new Set(currentList.map(item => item.id));
            const futureIds = new Set(futureList.map(item => item.id));

            const toAdd = futureList.filter(item => !currentIds.has(item.id));
            const toRemove = currentList.filter(item => !futureIds.has(item.id));

            return [toAdd, toRemove];
        },
        extractIds(objects) {
            return objects.map(obj => obj.id);
        },

        cleanList() {
            this.listUsers = [];
        },
        getSelItem() {
            if (!this.isOnlyOneSelected) {
                this.setError("Selected more than one option!");
                return;
            }
            const itemId = this.selectedItems[0];
            const itemObj = this.listUsers.find(item => item.user === itemId);
            return itemObj;
        },
        toggleSelectionItem(itemId) {
            if (this.isSelectedItem(itemId)) {
                // Удалить ID коллекции из массива, если она уже выбрана
                const index = this.selectedItems.indexOf(itemId);
                this.selectedItems.splice(index, 1);
            } else {
                // Добавить ID коллекции в массив, если она еще не выбрана
                this.selectedItems.push(itemId);
            }
            this.countItems();
        },
        isSelectedItem(itemId) {
            // Проверить, выбрана ли коллекция
            return this.selectedItems.includes(itemId);
        },
        selectAllItem() {
            // Добавить все ID коллекций в массив выбранных
            this.selectedItems = this.listUsers.map(item => item.id);
            this.countItems();
        },
        unSelectAllItem() {
            // Очистить массив выбранных коллекций
            this.selectedItems = [];
            this.countItems();
        },
        countItems() {
            this.isOnlyOneSelected = this.selectedItems.length === 1;
            this.isAtLeastOneSelected = this.selectedItems.length > 0;
            this.areItemsPresent = this.listUsers.length > 0;
            this.areAllSelected = this.selectedItems.length === this.listUsers.length;
        },
        async deleteSelectedItems() {
            const confirmation = window.confirm("Are you sure that you wanna delete selected items?");

            if (confirmation) {
                await this.doSequentially(this.selectedItems, this.deleteItem);
            }

            this.getAllItems(true);
        },
        async doSequentially(list, func) {
            if (list.length === 0) return;
            this.btn_delete_col_loading = true;
            const currentItem = list.shift();

            try {
                await func(currentItem);
                await this.doSequentially(list, func);
            } catch (error) {
                list.push(currentItem);
                this.setError("Error in doSequentially(): " + error);
            }
        },
        deleteItemFrInterface(user) {
            const index1 = this.listUsers.findIndex(item => item.user === user);
            if (index1 !== -1) {
                this.listUsers.splice(index1, 1);
            }

            // delete selected item from list
            // const index2 = this.selectedItems.indexOf(id);
            // if (index2 !== -1) {
            //   this.selectedItems.splice(index2, 1);
            // }

            this.countItems();
        },

        async deleteItem(id) {
            const user = this.listUsers.find(item => item.user === id);

            const [, toDelGroups] = this.prepareLists(user.groups, []);
            const [, toDelRoles] = this.prepareLists(user.direct_roles, []);

            this.btn_delete_col_loading = true;

            try {
                const responses = await Promise.all([
                    groupsService.deattach(user.user, this.extractIds(toDelGroups)),
                    rolesService.deattach(user.user, this.extractIds(toDelRoles)),
                ]);

                responses.forEach(data => {
                    if (this.$is_debug) console.log("Response message:", data);
                    // Обработка каждого ответа
                });

                // Ваши действия после успешного выполнения всех запросов

            } catch (error) {
                this.setError(error.body);
                accountsService.checkIfLogin(error.body, true);

                if (error.body === "Too Many Requests") {
                    this.btn_delete_col_is_active = false;
                    new Promise(resolve => setTimeout(resolve, 5000));
                    this.btn_delete_col_is_active = true;
                }
            } finally {
                this.btn_delete_col_loading = false;
            }
        },
    },
}
</script>
<style>
.table-container {
    max-width: 100%;
    /* Максимальная ширина контейнера */
    overflow-x: auto;
    /* Появление горизонтальной прокрутки при необходимости */
}

.table {
    width: 100%;
    /* Ширина таблицы равна 100% ширины контейнера */
    /* white-space: nowrap; Текст внутри ячеек не переносится */
}

.table th,
.table td {
    /* Сокрытие текста, который не помещается в ячейку */
    overflow: hidden;
    /* Отображение многоточия, если текст не помещается в ячейку */
    text-overflow: ellipsis;
    /* Перенос текста на следующую строку, если необходимо */
    word-wrap: break-word;
}

.btn-container {
    display: flex;
    flex-wrap: wrap;
}

.btn-container>.btn {
    margin-bottom: 0.5rem;
}
</style>
import { createApp } from "vue";

import App from './App.vue';
const app = createApp(App);

// /svg/folders-folder-svgrepo-com.svg
// <!-- https://www.svgrepo.com/svg/207624/folders-folder -->
app.config.globalProperties.$is_debug = false;
app.config.globalProperties.$site_name = 'SmartMCW';
app.config.globalProperties.$site_logo = '/images/oven.png';
app.config.globalProperties.$site_copy = '© 2024 SmartMCW';

import './plugins/axios'

import router from './router';
app.use(router);

import i18n from './plugins/i18n';
app.use(i18n);

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

app.mount("#app");

<template>
  <main class="form-signin w-100 m-auto">
    <div class="d-flex justify-content-between mb-3">
      <a href="/">
        <img class="mb-3" :src="$site_logo" :alt="$site_name" height="57" style="margin-left:-5px;">
      </a>
      <changeLang add_sel_classes="w-auto mr-auto"/>
    </div>

    <div class="d-flex justify-content-between mb-3 align-items-end">
      <h1 class="h3 fw-normal">{{ $t("googlecallback.title") }}</h1>
    </div>

    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="progress_bar" aria-valuemin="0" aria-valuemax="100" :style="{ width: progressBarWidth + '%' }"></div>
    </div>

    <div class="mt-3">
      <a href="#" @click="goCoverDirect">{{ $t("googlecallback.home_link") }}</a>
    </div>

    <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
    <p class="mt-5 mb-3 text-body-secondary">{{ $site_copy }}</p>
  </main>
</template>

<script>
import router from "@/router";
import { accountsService } from "@/components/ajax-common-account.js";
import changeLang from "@/components/module-common-change_lang.vue";

export default {
  name: 'GoogleRedirect',
  components: {
    changeLang
  },
  data() {
    return {
      timer: null,
      error: null,
      is_already_redirected: false,
      progressBarWidth: 0,
      is_got_response: false,
    };
  },
  methods: {
    sendCodeToServer(code) {
      accountsService.authToken(code)
        .then(data => {
          this.is_got_response = true;
          this.progressBarWidth = 100;

          this.checkIfUserLogged(data.message);
        })
        .catch(
          error => {
            this.is_got_response = true;
            this.progressBarWidth = 100;

            if (error.body) {
              const responseBody = error.body;
              this.error = responseBody;
            } else {
              this.error = error;
            }

            if (error.status && error.status === 502) {
              this.error = "Backend is not ready";
            }

            if (this.error === "Too Many Requests") {
              this.error = this.$i18n.t("googlecallback.error_too_many");
            }

            if (this.error === "You are already logged") {
              router.push("/home");
            }

            if (this.error === "Bad code!") {
              this.error = this.$i18n.t("googlecallback.error_bad_code");
            }
          }
        );
    },
    goCoverDirect() {
      this.$router.push('/');
    },
    setTimer() {
      if (this.timer !== null)
        clearInterval(this.timer);

      this.timer = setInterval(() => {
        if (this.is_got_response) clearInterval(this.timer);
        if (this.progressBarWidth === 100 && !this.is_got_response) {
          this.error = this.$i18n.t("googlecallback.error_req_timeout");
          clearInterval(this.timer);
        } else {
          this.progressBarWidth += 1;
        }
      }, 30);
    },
    goCover() {
      setTimeout(() => {
        if (!this.is_already_redirected)
          this.$router.push('/');
      }, 5000);
    },
    checkIfUserLogged(message) {
      if (message === "Welcome to the dark side, we have cookies") {
        this.is_already_redirected = true;
        router.push("/home");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    handleQueryParams() {
      const code = this.$route.query.code;
      if (code != null)
      {
        this.setTimer();
        this.sendCodeToServer(code);
      } else {
        this.error = this.$i18n.t("googlecallback.error_code_has_not_been_passed");
      }
    },
  },
  created() {
  },
  mounted() {
    document.body.classList.add('d-flex', 'justify-content-center', 'align-items-center')
    this.handleQueryParams();
  },
  unmounted() {
    document.body.classList.remove('d-flex', 'justify-content-center', 'align-items-center')
  }
}
</script>
<style>
/* signin */
html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}
</style>
<template>
  <form @submit.prevent="updateItem">
    <!-- https://getbootstrap.com/docs/5.0/forms/select/ -->

    <div class="form-floating">
      <input type="text" class="form-control" v-model="formdata_obj_id" name="formdata_obj_id" id="formdata_obj_id"
        placeholder="Object ID" disabled />
      <label for="formdata_obj_id">Object ID</label>
    </div>

    <div class="form-floating my-3">
      <input type="email" class="form-control" v-model="formdata_user" name="formdata_user" id="formdata_user"
        placeholder="User" v-bind:disabled="is_updating" :class="{'is-invalid': form_submitted && !isValidEmail(formdata_user) }"/>
      <label for="formdata_user">User Email</label>
    </div>

    <div class="form-floating my-3">
      <SelectSumComponent ref="SelSumRoles" label="role" @onChange="formdata_direct_roles = $event" />
    </div>

    <div class="form-floating my-3">
      <SelectSumComponent ref="SelSumGroups" label="group" @onChange="formdata_groups = $event" />
    </div>
  </form>
</template>

<script>
import { accountsService } from "@/components/ajax-common-account.js";
import { groupsService } from "@/components/ajax-common-groups.js";
import { rolesService } from "@/components/ajax-common-roles.js";
import SelectSumComponent from "@/components/module-common-select_sum.vue";


export default {
  name: 'UpdateAccessForm',
  components: {
    SelectSumComponent,
  },
  data() {
    return {
      form_submitted: false,
      form_is_loading: false,


      formdata_id: '',
      formdata_name: '',
      formdata_description: '',
      formdata_is_blocked: false,
      formdata_org_id: null,



      formdata_obj_id: '',
      formdata_user: '',

      current_direct_roles: [],
      current_groups: [],
      formdata_direct_roles: [],
      formdata_groups: [],
    };
  },
  props: {
    setError: {
      type: Function,
      required: true,
    },
    method_to_refresh: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },

    roles: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    groups: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },

    obj_id: {
      type: String,
      required: true,
    },
    getSelItem: {
      type: Function,
      required: true,
    },
    is_updating: {
      type: Boolean,
      default: function() {
          return false;
      },
      required: false,
    },
  },
  watch: {
    form_is_loading(newVal) {
      this.$emit("onLoading", newVal);
    },
    obj_id(newVal) {
      this.formdata_obj_id = newVal;
    },
  },
  methods: {
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    prepareLists(currentList, futureList) {
      const currentIds = new Set(currentList.map(item => item.id));
      const futureIds = new Set(futureList.map(item => item.id));

      const toAdd = futureList.filter(item => !currentIds.has(item.id));
      const toRemove = currentList.filter(item => !futureIds.has(item.id));

      return [toAdd, toRemove];
    },
    extractIds(objects) {
      return objects.map(obj => obj.id);
    },
    renderForm() {
      if (this.is_updating) {
        const data = this.getSelItem();
        this.formdata_user = data.user;
        this.current_direct_roles = data.direct_roles;
        this.current_groups = data.groups;
      }

      this.$refs.SelSumRoles.setData(this.roles, this.current_direct_roles);
      this.$refs.SelSumGroups.setData(this.groups, this.current_groups);
    },
    trimInput() {
      this.formdata_name = this.formdata_name.trim();
    },
    async updateItem() {
      this.form_submitted = true;

      if (!this.formdata_user) {
        this.setError("User email wasn't been provided.");
        return;
      }

      if (!this.isValidEmail(this.formdata_user)) {
        this.setError("User email is invalid.");
        return;
      }

      const [toAddGroups, toDelGroups] = this.prepareLists(this.current_groups, this.formdata_groups);
      const [toAddRoles, toDelRoles] = this.prepareLists(this.current_direct_roles, this.formdata_direct_roles);

      this.form_is_loading = true;

      try {
        const responses = await Promise.all([
          groupsService.attach(this.formdata_user, this.extractIds(toAddGroups)),
          groupsService.deattach(this.formdata_user, this.extractIds(toDelGroups)),
          rolesService.attach(this.formdata_user, this.extractIds(toAddRoles)),
          rolesService.deattach(this.formdata_user, this.extractIds(toDelRoles)),
        ]);

        responses.forEach(data => {
          if (this.$is_debug) console.log("Response message:", data);
          // Обработка каждого ответа
        });

        // Ваши действия после успешного выполнения всех запросов
        this.cleanupUpdateForm();
        this.closeModal();
        this.method_to_refresh(true);
      } catch (error) {
          this.setError(error.body);
          accountsService.checkIfLogin(error.body, true);

      } finally {
        this.form_is_loading = false;
      }
    },
    cleanupUpdateForm() {
      this.formdata_user = "";
      this.formdata_obj_id = "";
      this.formdata_direct_roles = [];
      this.formdata_groups = [];

      this.form_submitted = false;

      this.$refs.SelSumRoles.delData();
      this.$refs.SelSumGroups.delData();
    },
  },
  computed: {
    formdata_name_is_valid() {
      const trimmedName = this.formdata_name.trim();
      return trimmedName.length >= 4;
    }
  },
};
</script>

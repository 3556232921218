<template>
    <!-- https://getbootstrap.com/docs/5.0/forms/select/ -->
    <div class="form-floating">
      <select class="form-select" id="objSelect" v-model="obj_id" @change="onChangeObj">
        <option value="" disabled selected hidden>
          {{ objs.length === 0 ? 'Create an organization first (or refresh)' : 'Choose an object' }}
        </option>
        <option v-for="obj in objs" :key="obj.id" :value="obj.id">
          <!-- {{ obj_id !== obj.id && obj.is_cafe ? '— ' : '' }}{{ obj.name }} ({{ obj.id }}) -->
          {{ obj.is_cafe ? '↳ ' : '' }}{{ obj.name }} ({{ obj.id }})
        </option>
      </select>
      <label for="objSelect">Object:</label>
    </div>
  <hr />
</template>

<script>
import { ssService } from '@/components/ajax-common-ss';
import { orgsService } from "@/components/ajax-smartmcw-org.js";
import { cafesService } from "@/components/ajax-smartmcw-cafe.js";
import { accountsService } from "@/components/ajax-common-account.js";
import { features } from "@/components/module-common-features.js";

export default {
  name: "SelectorObjectComponent",
  data() {
    return {
      orgs: [],
      cafes: [],

      objs: [],
      obj_id: "",
    }
  },
  props: {
    setError: {
      type: Function,
      required: false,
    },
  },
  watch: {
    obj_id(newVal) {
      this.$emit('onChangeObjEv', newVal);
    },
    objs(newVal) {
      this.$emit('onChangeObjsEv', newVal);
    },
  },
  methods: {
    combineOrgsAndCafes(orgs, cafes) {
      const combined = [];

      orgs.forEach(org => {
        combined.push({
          name: org.name,
          is_cafe: false,
          id: org.id
        });

        cafes.filter(cafe => cafe.org_id === org.id).forEach(cafe => {
          combined.push({
            name: cafe.name,
            is_cafe: true,
            id: cafe.id
          });
        });
      });

      return combined;
    },

    onChangeObj() {
      if (this.obj_id) {
        ssService.setSSdirect('selected_obj_id', this.obj_id);
      }
    },
    getSavedObj() {
      const obj_id = ssService.getSSdirect('selected_obj_id');
      if (obj_id !== null && features.isElementInListById(this.objs, obj_id)) {
          this.obj_id = obj_id;
        }
    },

    async getAllObjs() {
      await this.getAllOrgs();
      await this.getAllCafes();
      this.objs = this.combineOrgsAndCafes(this.orgs, this.cafes);
    },

    async getAllOrgs() {
      await orgsService.getAll()
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.orgs = data;
        })
        .catch(
          async error => {
            if (error.body) {
              this.setError(error.body);
              accountsService.checkIfLogin(error.body, true);
            } else {
              this.setError(error.error);
            }
          }
        );
    },
    async getAllCafes(org_id = null) {
      await cafesService.getAll(false, org_id)
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.cafes = data;
        })
        .catch(
          async error => {
            if (error.body) {
              this.setError(error.body);

              accountsService.checkIfLogin(error.body, true);

              // if (error.body === "Too Many Requests") {
              // }
            } else {
              this.setError(error.error);
            }
          }
        );
    },
  },
  async mounted() {
    this.getAllObjs();
    this.getSavedObj();
  },
}
</script>
<style scoped>
@media (max-width: 640px) {
  .d-flex {
    flex-direction: column;
    /* flex-wrap: wrap; */
  }
}

.d-flex {
  gap: 10px;
}

.form-floating {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

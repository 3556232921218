<template>
    <!-- https://getbootstrap.com/docs/5.0/forms/select/ -->
    <div class="form-floating">
      <select class="form-select" id="itemSelect" v-model="item_id" @change="onChangeCafe">
        <option value="" disabled selected hidden>
          {{ items.length === 0 ? 'Create a cafe first (or refresh)' : 'Choose a cafe' }}
        </option>
        <option v-for="item in items" :key="item.id" :value="item.id">
          {{ item.org.name }} on {{ item.name }} ({{ item.id }})
        </option>
      </select>
      <label for="itemSelect">Cafe:</label>
    </div>
  <hr />
</template>

<script>
import { ssService } from '@/components/ajax-common-ss';
import { cafesService } from "@/components/ajax-smartmcw-cafe.js";
import { accountsService } from "@/components/ajax-common-account.js";
import { features } from "@/components/module-common-features.js";

export default {
  name: "SelectorCafeComponent",
  data() {
    return {
      items: [],
      item_id: "",
    }
  },
  props: {
    setError: {
      type: Function,
      required: false,
    },
  },
  watch: {
    item_id(newVal) {
      this.$emit('onChangeCafeEv', newVal);
    },
    items(newVal) {
      this.$emit('onChangeCafesEv', newVal);
    },
  },
  methods: {
    onChangeCafe() {
      if (this.item_id) {
        ssService.setSSdirect('selected_cafe_id', this.item_id);
      }
    },
    getSavedCafe() {
      const item_id = ssService.getSSdirect('selected_cafe_id');

      if (item_id !== null && features.isElementInListById(this.items, item_id)) {
          this.item_id = item_id;
        }
    },

    async getAllCafes(org_id = null) {
      await cafesService.getAll(false, org_id)
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.items = data;
        })
        .catch(
          async error => {
            if (error.body) {
              this.setError(error.body);

              accountsService.checkIfLogin(error.body, true);

              // if (error.body === "Too Many Requests") {
              // }
            } else {
              this.setError(error.error);
            }
          }
        );
    },
  },
  async mounted() {
    await this.getAllCafes();
    this.getSavedCafe();
  },
}
</script>
<style scoped>
@media (max-width: 640px) {
  .d-flex {
    flex-direction: column;
    /* flex-wrap: wrap; */
  }
}

.d-flex {
  gap: 10px;
}

.form-floating {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

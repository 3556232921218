<template>
  <h1>Users</h1>
  <div v-if="error" class="alert alert-danger mb-3">{{ error }}</div>


  <button class="btn btn-primary py-2" type="submit" v-bind:disabled="loading" @click="getAllUsers">
    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Refresh
  </button>


  <div class="m-0 border-0">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">email</th>
          <th scope="col">name</th>
          <th scope="col">surname</th>
          <th scope="col">is_blocked</th>
          <th scope="col">email_is_verified</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="s_user in users" :key="s_user.id">
          <th scope="row">{{ s_user.id }}</th>
          <td>{{ s_user.email }}</td>
          <td>{{ s_user.name }}</td>
          <td>{{ s_user.surname }}</td>
          <td>{{ s_user.is_blocked }}</td>
          <td>{{ s_user.email_is_verified }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import { usersService } from "@/components/ajax-common-users.js";
import { accountsService } from "@/components/ajax-common-account.js";

export default {
  name: "AdminUsers",
  components: {
  },
  data() {
    return {
      error: '',
      loading: false,

      users: '',
    }
  },
  watch: {
  },
  methods: {
    async setError(text) {
      this.error = text;
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.error = '';
    },
    getAllUsers() {
      this.loading = true;
      usersService.getAll()
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.users = data;
          this.loading = false;
        })
        .catch(
          error => {
            if (error.body) {
              this.setError(error.body);

              accountsService.checkIfLogin(error.body, true);

            } else {
              // Обработка ошибки без тела ответа
              // console.error("Error:", error.error);
              this.setError(error.error);
            }
            this.loading = false;
          }
        );
    },
  },
  mounted() {
    this.getAllUsers();
  },
  created() {
  }
}
</script>

export const features = {
    unionDiff,
    extractIds,
    refMethod,
    isElementInListById,
};

export { ListComponent };

function refMethod(refName, methodName, ...args) {
    // instead of using labda functions:
    // () => { if ($refs.createForm) $refs.createForm.cleanupCreateForm() }
    if (this.$refs[refName] && typeof this.$refs[refName][methodName] === 'function') {
        this.$refs[refName][methodName](...args);
    }
}

function extractIds(objects) {
    return objects.map(obj => obj.id);
}

function unionDiff(currentList, futureList) {
    const currentIds = new Set(currentList.map(item => item.id));
    const futureIds = new Set(futureList.map(item => item.id));

    const toAdd = futureList.filter(item => !currentIds.has(item.id));
    const toRemove = currentList.filter(item => !futureIds.has(item.id));

    return [toAdd, toRemove];
}

function isElementInListById(list, id) {
    return list.some(item => item.id === id);
}

class ListComponent {
    // store only ids (by '_key_name')
    _sel_items = []
    _all_items = []
    _is_do_sequentially = false

    constructor(setError, key_name = 'id') {
        this._setError = setError;
        this._key_name = key_name;
    }

    get is_do_sequentially() {
        return this._is_do_sequentially;
    }

    get sel_items() {
        return this._sel_items;
    }

    get all_items() {
        return this._all_items;
    }

    set all_items(value = []) {
        // add checking that it is array
        this._all_items = value;
    }

    isListEmpty() {
        return this._all_items.length === 0;
    }
    isOnlyOneSelected() {
        return this._sel_items.length === 1;
    }
    isAtLeastOneSelected() {
        return this._sel_items.length > 0;
    }
    areItemsPresent() {
        return this._all_items.length > 0;
    }
    areAllSelected() {
        return this._sel_items.length === this._all_items.length;
    }
    getSelectedItem() {
        if (!this.isOnlyOneSelected()) {
            this._setError("Selected less / more than one option!");
            return;
        }
        const itemId = this._sel_items[0];
        return this.getItemById(itemId);
    }

    getItemById(itemId) {
        const itemObj = this._all_items.find(item => item[this._key_name] === itemId);
        return itemObj;
    }

    toggleItem(itemId) {
        if (this.isSelected(itemId)) {
            const index = this._sel_items.indexOf(itemId);
            this._sel_items.splice(index, 1);
        } else {
            this._sel_items.push(itemId);
        }
    }
    isSelected(itemId) {
        return this._sel_items.includes(itemId);
    }
    selectAll() {
        this._sel_items = this._all_items.map(item => item[this._key_name]);
    }
    unSelectAll() {
        this._sel_items = [];
    }
    cleanAll() {
        this._all_items = [];
    }


    async doSequentially(list, func, in_recursion = false) {
        // it accepts ids list
        if (list.length === 0) return;

        if (!in_recursion) this._is_do_sequentially = true;
        const itemId = list.shift();

        try {
            await func(this.getItemById(itemId));
            await this.doSequentially(list, func, true);
        } catch (error) {
            list.push(itemId);
            this._setError("Error in doSequentially(): " + error);
        } finally {
            if (!in_recursion) this._is_do_sequentially = false;
        }
    }

    delItemById(itemId) {
        const index1 = this._all_items.findIndex(item => item[this._key_name] === itemId);
        if (index1 !== -1) {
            this._all_items.splice(index1, 1);
        }

        const index2 = this._sel_items.indexOf(itemId);
        if (index2 !== -1) {
            this._sel_items.splice(index2, 1);
        }
    }

    sortItems(type = "str", key = "name") {
        switch(type) {
            case 'str':
                this._all_items.sort((a, b) => a[key].localeCompare(b[key]));
                break;
            case 'int':
                this._all_items.sort((a, b) => a[key] - b[key]);
                break;
            case 'date':
                this._all_items.sort((a, b) => {
                    return new Date(a[key]) - new Date(b[key]);
                });
                break;
            default:
                this._all_items.sort((a, b) => a.id - b.id);
                break;
          }
    }
}

// l2 = [{ "id": 1, "name": "first" }, { "id": 2, "name": "second" }, { "id": 3, "name": "third" }, { "id": 4, "name": "fourth" }, { "id": 5, "name": "fift" }, { "id": 6, "name": "sixt" },]
// l1 = new ListComponent((mes) => { console.log(mes); })
// l1.all_items = l2
// l1.all_items
// l1.toggleItem(2);
// l1.toggleItem(5);
// l1.getSelectedItem();

// l1.doSequentially([2,1,6], (mes) => { console.log(mes); })

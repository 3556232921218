<template>
    <div class="d-flex" v-if="!(isOnlyOneItem() && hide_if_possible)">
        <!-- https://getbootstrap.com/docs/5.0/forms/select/ -->
        <div class="form-floating flex-grow-1">
            <select class="form-select" id="orgSelect" v-model="formdata_org_id" @change="onChangeOrg">
                <option value="" disabled selected hidden>{{ orgs.length === 0 ? 'Create an organization first (or refresh)' : 'Choose an organization' }}</option>
                <option v-for="org in orgs" v-bind:key="org.id" v-bind:value="org.id">{{ org.name }} ({{ org.id }})</option>
            </select>
            <label for="orgSelect">Organization:</label>
        </div>
        <div class="form-floating flex-grow-1" v-if="en_cafes && en_cafes_selector">
            <select class="form-select" id="cafeSelect" v-model="formdata_cafe_id" @change="onChangeCafe" :disabled="cafes.length === 0">
                <option value="" disabled selected hidden>{{ cafes.length === 0 ? 'There are no available cafes in the organization' : 'Choose cafes' }}</option>
                <option v-for="cafe in cafes" :key="cafe.id" :value="cafe.id">{{ cafe.name }} ({{ cafe.id }})
                </option>
            </select>
            <label for="cafeSelect">Cafes:</label>
        </div>
    </div>
    <hr v-if="!(isOnlyOneItem() && hide_if_possible)" />
</template>

<script>
import { ssService } from '@/components/ajax-common-ss';
import { orgsService } from "@/components/ajax-smartmcw-org.js";
import { cafesService } from "@/components/ajax-smartmcw-cafe.js";
import { accountsService } from "@/components/ajax-common-account.js";

export default {
  name: "SelectorOrgCafeComponent",

  components: {
  },

  data() {
    return {
      orgs: [],
      cafes: [],
      formdata_org_id: "",
      formdata_cafe_id: "",
    }
  },
  props: {
    setError: {
      type: Function,
      required: false,
    },
    en_cafes: {
      type: Boolean,
      default: function() {
          return false;
      },
      required: false,
    },
    en_cafes_selector: {
      type: Boolean,
      default: function() {
          return false;
      },
      required: false,
    },
    hide_if_possible: {
      type: Boolean,
      default: function() {
          return false;
      },
      required: false,
    },
  },
  watch: {
    formdata_org_id(newVal) {
      this.$emit('onChangeOrgEv', newVal);
    },
    formdata_cafe_id(newVal) {
      this.$emit('onChangeCafeEv', newVal);
    },
    orgs(newVal) {
      this.$emit('onChangeOrgsEv', newVal);
    },
    cafes(newVal) {
      this.$emit('onChangeCafesEv', newVal);
    },
  },
  methods: {
    isOnlyOneItem() {
      return (this.orgs.length === 1 && this.cafes.length === 1);
    },
    getOrgs() {
      return this.orgs;
    },
    async getAllOrgs() {
      await orgsService.getAll()
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          this.orgs = data;
        })
        .catch(
          async error => {
            if (error.body) {
              this.setError(error.body);

              accountsService.checkIfLogin(error.body, true);

              // if (error.body === "Too Many Requests") {
              // }
            } else {
              this.setError(error.error);
            }
          }
        );
    },
    onChangeCafe() {
      if (this.formdata_cafe_id) {
        ssService.setSSdirect('selected_cafe_id', this.formdata_cafe_id);
      }
    },
    async onChangeOrg() {
      if (this.formdata_org_id) {
        ssService.setSSdirect('selected_org_id', this.formdata_org_id);

        if (this.en_cafes) {
          this.cafes = [];
          await this.getAllCafes(this.formdata_org_id);

          this.getSavedCafe();
        }
      }
    },
    isCafeInOrg() {
      if (this.formdata_org_id && this.formdata_cafe_id) {
        return this.cafes.some(item => item.id === this.formdata_cafe_id && item.org_id === this.formdata_org_id);
      }
      else return false;
    },
    getSavedOrg() {
      this.formdata_org_id = ssService.getSSdirect('selected_org_id');

      const isIdInArray = this.orgs.some(org => org.id === this.formdata_org_id);
      if (!isIdInArray) {
        this.formdata_org_id = this.orgs.length > 0 ? this.orgs[0].id : '';
        this.onChangeOrg();
      }
    },
    getSavedCafe() {
      this.formdata_cafe_id = ssService.getSSdirect('selected_cafe_id');

      // load orgs, cafe first
      if (!this.isCafeInOrg()) {
          this.formdata_cafe_id = this.cafes.length > 0 ? this.cafes[0].id : '';
          ssService.unsetSS('selected_cafe_id');
          this.onChangeCafe();
        }
    },
    async getAllCafes(org_id = null) {
      if (org_id) {
        await cafesService.getAll(false, org_id)
          .then(data => {
            if (this.$is_debug) console.log("Response message:", data);
            this.cafes = data;
          })
          .catch(
            async error => {
              if (error.body) {
                this.setError(error.body);

                accountsService.checkIfLogin(error.body, true);

                // if (error.body === "Too Many Requests") {
                // }
              } else {
                this.setError(error.error);
              }
            }
          );
      }
    },
  },
  async mounted() {
    await this.getAllOrgs();
    this.getSavedOrg();

    if (this.en_cafes && this.formdata_org_id) {
      await this.getAllCafes(this.formdata_org_id);
      this.getSavedCafe();
    }
  },
}
</script>
<style scoped>
@media (max-width: 640px) {
  .d-flex {
    flex-direction: column;
    /* flex-wrap: wrap; */
  }
}

.d-flex {
    gap: 10px;
}

.form-floating.flex-grow-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>

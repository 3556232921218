<template>
  <div :class="div_classes">
    <select :class="sel_classes" v-model="selected_locale" @change="changeLanguage">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'ChangeLang',
  data() {
    return {
      div_classes: this.add_div_classes,
      sel_classes: "form-select form-select-sm " + this.add_sel_classes,
      selected_locale: localStorage.getItem('selected_locale') || this.$i18n.locale
    };
  },
  props: {
    add_div_classes: {
      type: String,
      required: false
    },
    add_sel_classes: {
      type: String,
      required: false
    }
  },
  methods: {
    setLocale() {
      // change language to selected in local storage
      const savedLocale = localStorage.getItem('selected_locale');
      if (savedLocale) {
        this.$i18n.locale = savedLocale;
      }
    },
    changeLanguage() {
      localStorage.setItem('selected_locale', this.selected_locale);
      this.$i18n.locale = this.selected_locale;
    },
  },
  computed: {},
  created() {
  },
  mounted() {
    this.setLocale();
  }
};
</script>

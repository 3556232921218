<template>
  <form @submit.prevent="updateItem">
    <!-- https://getbootstrap.com/docs/5.0/forms/select/ -->
    <div class="form-floating">
      <select class="form-select" id="orgSelect" v-model="formdata_org_id">
        <option v-if="orgs.length === 0" disabled selected style="color: red;">Please update an organization
          first</option>
        <option v-else-if="orgs.length > 0" selected :value="orgs[0].id">{{ orgs[0].name }}
          ({{ orgs[0].id }})</option>
        <option v-for="org in orgs_wo_first" v-bind:key="org.id" v-bind:value="org.id">{{ org.name }} ({{
    org.id }})</option>
      </select>
      <label for="orgSelect">Organization:</label>
      <div v-show="form_submitted && !formdata_org_id" class="invalid-feedback">Please select an organization</div>
      <div v-show="formdata_org_id" class="invalid-feedback">Please select an organization2</div>
      <div v-show="form_submitted" class="invalid-feedback">Please select an organization3 {{ formdata_org_id }}</div>
    </div>


    <div class="form-floating my-3">
      <input type="text" class="form-control" v-model="formdata_name" name="formdata_name" id="formdata_name"
        placeholder="Cafe name"
        :class="{ 'is-invalid': form_submitted && (!formdata_name || !formdata_name_is_valid) }" />
      <label for="formdata_name" v-show="!form_submitted || formdata_name">Cafe</label>
      <div v-show="form_submitted && !formdata_name" class="invalid-feedback">Name is required</div>
      <div v-show="form_submitted && !formdata_name_is_valid" class="invalid-feedback">Name must be at least 4
        characters long</div>
    </div>

    <div class="form-floating my-3">
      <textarea class="form-control" v-model="formdata_description" name="formdata_description"
        id="formdata_description" placeholder="Cafe description" style="height: 200px;"></textarea>
      <label for="formdata_description">Description</label>
    </div>

    <div class="form-check text-start my-3">
      <input class="form-check-input" type="checkbox" v-model="formdata_is_blocked" name="formdata_is_blocked"
        value="formdata_is_blocked" id="formdata_is_blocked" data-bs-toggle="tooltip"
        title="Check this box if the cafe should be blocked">
      <label class="form-check-label" for="formdata_is_blocked">
        Blocked cafe
      </label>
    </div>
  </form>
</template>

<script>
import { accountsService } from "@/components/ajax-common-account.js";
import { cafesService } from "@/components/ajax-smartmcw-cafe.js";

export default {
  name: 'UpdateCafeForm',
  data() {
    return {
      form_submitted: false,
      form_is_loading: false,

      formdata_id: '',
      formdata_name: '',
      formdata_description: '',
      formdata_is_blocked: false,
      formdata_org_id: null,
    };
  },
  props: {
    setError: {
      type: Function,
      required: false,
    },
    method_to_refresh: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: false,
    },

    getSelItem: {
      type: Function,
      required: true,
    },
    orgs: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    form_is_loading(newVal) {
      this.$emit("onLoading", newVal);
    },
  },
  methods: {
    renderForm() {
      const data = this.getSelItem();

      this.formdata_id = data.id;
      this.formdata_name = data.name;
      this.formdata_description = data.description;
      this.formdata_is_blocked = data.is_blocked;
      this.formdata_org_id = data.org_id;
    },
    trimInput() {
      this.formdata_name = this.formdata_name.trim();
    },
    updateItem() {
      this.form_submitted = true;
      const { formdata_id, formdata_name, formdata_description, formdata_is_blocked, formdata_org_id } = this;

      if (!formdata_id) {
        this.setError("Cafe ID wasn't been provided.");
        return;
      }

      if (!formdata_org_id) {
        this.setError("Org ID wasn't been provided.");
        return;
      }

      // console.log(remember_me);
      // stop here if form is invalid
      if (!formdata_name || !this.formdata_name_is_valid) {
        return;
      }

      // console.log(email, password);

      this.form_is_loading = true;
      cafesService.update(formdata_id, formdata_name, formdata_description, formdata_is_blocked, formdata_org_id)
        .then(data => {
          if (this.$is_debug) console.log("Response message:", data);
          // router.push("/home");
          this.cleanupUpdateForm();
          this.closeModal();
          this.method_to_refresh(true);
          this.form_is_loading = false;
        })
        .catch(
          error => {
            if (error.body) {
              this.setError(error.body);
              accountsService.checkIfLogin(error.body, true);

            } else {
              this.setError(error.error);
            }
            this.form_is_loading = false;
          }
        );
    },
    cleanupUpdateForm() {
      this.formdata_name = '';
      this.formdata_description = '';
      this.formdata_is_blocked = false;

      this.form_submitted = false;
    },
  },
  computed: {
    orgs_wo_first() {
      return this.orgs.slice(1);
    },
    formdata_name_is_valid() {
      const trimmedName = this.formdata_name.trim();
      return trimmedName.length >= 4;
    }
  },
};
</script>

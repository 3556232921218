<template>
    <CreateMenuModal v-if="en_redactor" :method_to_refresh="getAllItems" :organizations="orgs" :current_org="org_id" />
    <UpdateMenuModal v-if="en_redactor" :method_to_refresh="getAllItems" :organizations="orgs" :current_org="org_id"
        :getSelItem="getSelItem" />

    <div class="btn-container">
        <button v-if="en_refresh_but" class="btn btn-primary py-2 mr-2" type="submit"
            v-bind:disabled="!btn_refresh_col_is_active" @click="getAllItems(true)">
            <span v-if="btn_refresh_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Refresh
        </button>

        <button v-if="en_redactor" class="btn btn-primary py-2 mr-2" type="submit" data-bs-toggle="modal"
            data-bs-target="#createModal">
            <span v-if="btn_create_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Create
        </button>

        <button v-if="en_redactor && en_multiple_choice" class="btn btn-primary py-2 mr-2" type="submit"
            @click="selectAllItem" v-bind:disabled="!areItemsPresent || areAllSelected">
            <span v-if="btn_selall_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Select All
        </button>

        <button v-if="en_multiple_choice" class="btn btn-primary py-2 mr-2" type="submit" @click="unSelectAllItem"
            v-bind:disabled="!isAtLeastOneSelected">
            <span v-if="btn_unselall_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Unselect All
        </button>

        <button v-if="en_redactor" class="btn btn-primary py-2 mr-2" type="submit" v-bind:disabled="!isOnlyOneSelected"
            data-bs-toggle="modal" data-bs-target="#updateModal">
            <span v-if="btn_edit_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Edit
        </button>

        <button v-if="en_redactor" class="btn btn-primary py-2" type="submit" @click="deleteSelectedItems"
            v-bind:disabled="!isAtLeastOneSelected || !btn_delete_col_is_active">
            <span v-if="btn_delete_col_loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            Delete
        </button>
    </div>

    <div v-if="isListEmpty" class="mt-3"><b>There are no available menus (choose another cafe, organization or create a new one menu first).</b></div>

    <div class="album py-5" v-if="!isListEmpty">
        <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-5 g-3">

            <div v-for="s_item in listItems" :key="s_item.id" class="col d-flex align-items-stretch">
                <div class="card shadow-sm selectable-card" :class="{ 'selected-card': isSelectedItem(s_item.id) }">
                    <img class="bd-placeholder-img card-img-top" :src="s_item.cover_image || default_menu_image"
                        @click="toggleSelectionItem(s_item.id)" />
                    <div class="card-body d-flex flex-column">
                        <div class="mb-3" @click="toggleSelectionItem(s_item.id)">
                            <div class="fw-bold mb-2">{{ s_item.name }}</div>
                            <div class="card-text" style="white-space: pre-line;">{{ s_item.description }}</div>
                        </div>

                        <div class="mt-auto">
                            <div v-if="cafes.length > 0" class="tags mb-3">
                                <span v-for="cafe in getCafeNames(s_item.cafes, cafes)" :key="cafe"
                                    class="badge bg-primary">{{ cafe }}</span>
                            </div>

                            <div class="justify-content-between d-flex">
                                <div v-if="en_add_buttons" class="btn-group">
                                    <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                                    <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                                </div>
                                <small class="text-body-secondary align-self-center">{{ getDurationStr(s_item.command)
                                    }}</small>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import CreateMenuModal from '@/components/modal-smartmcw-create_menu.vue'
import UpdateMenuModal from '@/components/modal-smartmcw-update_menu.vue'
import { accountsService } from "@/components/ajax-common-account.js";
import { menusService } from "@/components/ajax-smartmcw-menu.js";

export default {
    name: "MenuComponent",
    components: {
        CreateMenuModal,
        UpdateMenuModal,
    },
    data() {
        return {
            btn_refresh_col_loading: false,
            btn_refresh_col_is_active: true,
            btn_create_col_loading: false,
            btn_selall_col_loading: false,
            btn_unselall_col_loading: false,
            btn_edit_col_loading: false,
            btn_delete_col_is_active: true,
            btn_delete_col_loading: false,

            listItems: [],
            selectedItems: [],
            isOnlyOneSelected: false,
            isAtLeastOneSelected: false,
            areItemsPresent: false,
            areAllSelected: false,

            default_menu_image: "/default_menu.jpg",
        }
    },
    props: {
        formdata_org_id: {
            type: String,
            required: false,
        },
        formdata_cafe_id: {
            type: String,
            required: false,
        },
        cafes: {
            type: Array,
            default: function () {
                return [];
            },
            required: false,
        },
        orgs: {
            type: Array,
            default: function () {
                return [];
            },
            required: false,
        },
        en_add_buttons: {
            type: Boolean,
            required: false,
            default: function () {
                return false;
            },
        },
        en_redactor: {
            type: Boolean,
            required: false,
            default: function () {
                return false;
            },
        },
        en_multiple_choice: {
            type: Boolean,
            required: false,
            default: function () {
                return false;
            },
        },
        en_refresh_but: {
            type: Boolean,
            required: false,
            default: function () {
                return false;
            },
        },
        setError: {
            type: Function,
            required: false,
        },
    },
    watch: {
        formdata_org_id(newVal) {
            if (newVal === "" || newVal === null) {
                this.cleanList();
                this.org_id = null;
                this.cafe_id = null;
            }
            else this.getByOrgId(newVal);
        },
        formdata_cafe_id(newVal) {
            if (newVal === "" || newVal === null) {
                this.cleanList();
                this.org_id = null;
                this.cafe_id = null;
            }
            else this.getByCafeId(newVal);
        },
        current_item(newVal) {
            if (newVal === "") newVal = null;
            this.$emit('onChange', newVal);
        },
    },
    computed: {
        current_item() {
            return this.getSelItem();
        },
        isListEmpty() {
            return this.listItems.length === 0;
        }
    },
    methods: {
        getCafeNames(idList, dataList) {
            return idList.map(id => {
                const dataItem = dataList.find(item => item.id === id);
                return dataItem ? dataItem.name : null;
            });
        },
        getByOrgId(org_id) {
            this.org_id = org_id;
            this.cafe_id = null;
            this.getAllItems();
        },
        getByCafeId(cafe_id) {
            this.org_id = null;
            this.cafe_id = cafe_id;
            this.getAllItems();
        },
        cleanList() {
            this.listItems = [];
        },
        getDurationStr(commandJson) {
            let command;
            try {
                command = JSON.parse(commandJson);
            } catch (error) {
                return "?";
            }

            if (!Array.isArray(command)) {
                return "?";
            }

            let totalDurationSeconds = 0;
            for (const item of command) {
                if (typeof item === 'object' && 'duration' in item) {
                    totalDurationSeconds += item.duration;
                }
            }

            // const totalDurationSeconds = command.reduce((total, item) => total + item.duration, 0);
            const minutes = Math.floor(totalDurationSeconds / 60);
            const seconds = totalDurationSeconds % 60;

            let durationStr = "";
            if (minutes > 0) {
                durationStr += `${minutes} min `;
            }
            if (seconds > 0) {
                durationStr += `${seconds} sec`;
            }
            if (durationStr === "") {
                durationStr = '?';
            }
            return durationStr.trim();
        },
        getSelItem() {
            this.countItems();
            if (!this.isOnlyOneSelected) {
                // this.setError("Selected more than one option!");
                return null;
            }
            const itemId = this.selectedItems[0];
            const itemObj = this.listItems.find(item => item.id === itemId);
            return itemObj;
        },
        async getAllItems(force = false) {
            if (this.org_id || this.cafe_id) {
                this.cleanList();

                this.btn_refresh_col_loading = true;
                await menusService.getAll(force, this.org_id, this.cafe_id)
                    .then(data => {
                        if (this.$is_debug) console.log("Response message:", data);

                        this.listItems = data;
                        this.listItems.sort((a, b) => {
                            return new Date(a.created_at) - new Date(b.created_at);
                        });

                        this.btn_refresh_col_loading = false;
                        this.countItems();
                    })
                    .catch(
                        async error => {
                            this.btn_refresh_col_loading = false;

                            if (error.body) {
                                this.setError(error.body);

                                accountsService.checkIfLogin(error.body, true);

                                if (error.body === "Too Many Requests") {
                                    this.btn_refresh_col_is_active = false;
                                    await new Promise(resolve => setTimeout(resolve, 5000));
                                    this.btn_refresh_col_is_active = true;
                                }
                            } else {
                                this.setError(error.error);
                            }
                        }
                    );
            }
        },
        toggleSelectionItem(itemId) {
            const found = this.isSelectedItem(itemId);
            if (found) {
                // Удалить ID коллекции из массива, если она уже выбрана
                const index = this.selectedItems.indexOf(itemId);
                this.selectedItems.splice(index, 1);
            } else {
                if (!this.en_multiple_choice) this.selectedItems = [];
                this.selectedItems.push(itemId);
            }
            this.countItems();
        },
        isSelectedItem(itemId) {
            // Проверить, выбрана ли коллекция
            return this.selectedItems.includes(itemId);
        },
        selectAllItem() {
            // Добавить все ID коллекций в массив выбранных
            this.selectedItems = this.listItems.map(item => item.id);
            this.countItems();
        },
        unSelectAllItem() {
            // Очистить массив выбранных коллекций
            this.selectedItems = [];
            this.countItems();
        },
        countItems() {
            this.isOnlyOneSelected = this.selectedItems.length === 1;
            this.isAtLeastOneSelected = this.selectedItems.length > 0;
            this.areItemsPresent = this.listItems.length > 0;
            this.areAllSelected = this.selectedItems.length === this.listItems.length;
        },

        async deleteSelectedItems() {
            const confirmation = window.confirm("Are you sure that you wanna delete selected items?");

            if (confirmation) {
                await this.doSequentially(this.selectedItems, this.deleteItem);
            }

            this.getAllItems(true);
        },
        async doSequentially(list, func) {
            if (list.length === 0) return;
            this.btn_delete_col_loading = true;
            const currentItem = list.shift();

            try {
                await func(currentItem);
                await this.doSequentially(list, func);
            } catch (error) {
                list.push(currentItem);
                this.setError("Error in doSequentially(): " + error);
            }
        },
        deleteItemFrInterface(id) {
            const index1 = this.listItems.findIndex(item => item.id === id);
            if (index1 !== -1) {
                this.listItems.splice(index1, 1);
            }

            // delete selected item from list
            // const index2 = this.selectedItems.indexOf(id);
            // if (index2 !== -1) {
            //   this.selectedItems.splice(index2, 1);
            // }

            this.countItems();
        },
        async deleteItem(id) {
            await menusService.remove(id)
                .then(data => {
                    if (data === null) {
                        this.deleteItemFrInterface(id);
                    }
                    this.btn_delete_col_loading = false;
                    return true;
                })
                .catch(
                    error => {
                        this.btn_delete_col_loading = false;
                        if (error.body) {
                            const responseBody = error.body;
                            this.setError("Error from server: " + responseBody);

                            if (responseBody === "Too Many Requests") {
                                this.btn_delete_col_is_active = false;
                                new Promise(resolve => setTimeout(resolve, 5000));
                                this.btn_delete_col_is_active = true;
                            }
                        } else {
                            this.setError("Error: " + error.error);
                        }
                        return false;
                    }
                );
        },
    },
    mounted() {
    },
}
</script>
<style>
.selectable-card {
    cursor: pointer;
}

.selectable-card:hover {
    /* border: 2px solid blue; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}

.selected-card {
    border: 2px solid blue;
}

.tags {
    display: flex;
    flex-wrap: wrap;
}

.tags .badge {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.btn-container {
    display: flex;
    flex-wrap: wrap;
}

.btn-container>.btn {
    margin-bottom: 0.5rem;
}
</style>